import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA component

const Form_en = () => {
  const btndisabled = {
    backgroundColor: "#e0e0e0",
    color: "#ffffff",
    borderColor: "#e0e0e0",
  };
  const btnenabled = {
    backgroundColor: "#029acb",
    color: "#ffffff",
    borderColor: "#029acb",
  };
  const [formData, setFormData] = useState({
    name: "",
    prenom: "",
    email: "",
    phone: "",
    message: "",
  });

  // State to handle the response or error messages
  const [responseMessage, setResponseMessage] = useState("");
  const [Nom, setNom] = useState("");

  const recaptchaSiteKey = process.env.REACT_APP_reCaptcha;
  const recaptchaSiteKey_localhost = process.env.REACT_APP_reCaptcha_localhost;

  const [errors, setErrors] = useState({});
  const [Captcha, setCaptcha] = useState(true); // State to track captcha change and disable button
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission

  // Handle captcha value
  const captchavalue = (e) => {
    setCaptcha(false);
  };

  // Handler to update state when input values change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validate the form
  const validateForm = () => {
    let formErrors = {};

    if (!formData.name.trim()) formErrors.name = "Name is required";
    if (!formData.email.trim()) formErrors.email = "Email is required";
    if (!formData.phone.trim()) formErrors.phone = "Phone is required";
    if (!formData.message.trim()) formErrors.message = "Message is required";

    setErrors(formErrors);

    // If there are no errors, return true
    return Object.keys(formErrors).length === 0;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields before submission
    if (!validateForm()) {
      return;
    }

    // API endpoint where data will be sent
    const apiUrl = process.env.REACT_APP_API_PowerAuto;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), // Send formData as JSON
      });

      if (response.ok) {
        const data = await response.json();
        setIsSubmitted(true); // Update the state to true when the form is submitted
        setNom(data.data.Name);
        setResponseMessage("Form submitted successfully!");
      } else {
        setResponseMessage("There was an error submitting the form.");
      }
    } catch (error) {
      setResponseMessage("An error occurred: " + error.message);
    }

    // Optionally, reset the form
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
    setTimeout(() => setIsSubmitted(true), 100);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
      }}
    >
      {/*================================= Form */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="row">
                <div className="col-sm-12">
                  {!isSubmitted ? (
                    // Form is displayed when isSubmitted is false
                    <div>
                      <h3 className="mb-40">Nous Contacter</h3>

                      <br />

                      <div id="formmessage">
                        {responseMessage || "Success/Error Message Goes Here"}
                      </div>
                      <form id="contactform" onSubmit={handleSubmit}>
                        <div className="contact-form clearfix">
                          <div className="section-field">
                            <input
                              id="name"
                              type="text"
                              value={formData.name}
                              onChange={handleInputChange}
                              placeholder="Name & First name*"
                              className="form-control"
                              name="name"
                              required
                            />
                            {errors.name && (
                              <span className="error-text">{errors.name}</span>
                            )}
                          </div>

                          <div className="section-field">
                            <input
                              type="email"
                              placeholder="Email*"
                              value={formData.email}
                              onChange={handleInputChange}
                              required
                              className="form-control"
                              name="email"
                            />
                            {errors.email && (
                              <span className="error-text">{errors.email}</span>
                            )}
                          </div>

                          <div className="section-field">
                            <input
                              type="text"
                              placeholder="Phone*"
                              value={formData.phone}
                              onChange={handleInputChange}
                              required
                              className="form-control"
                              name="phone"
                            />
                            {errors.phone && (
                              <span className="error-text">{errors.phone}</span>
                            )}
                          </div>

                          <div className="section-field textarea">
                            <textarea
                              className="form-control input-message"
                              placeholder="Message*"
                              rows={7}
                              name="message"
                              value={formData.message}
                              onChange={handleInputChange}
                              required
                            />
                            {errors.message && (
                              <span className="error-text">
                                {errors.message}
                              </span>
                            )}
                          </div>

                          <div
                            className="section-field submit-button"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {/* reCAPTCHA Widget */}
                            <ReCAPTCHA
                              sitekey={recaptchaSiteKey}
                              onChange={captchavalue}
                            />
                            <br />
                            <input
                              type="hidden"
                              name="action"
                              defaultValue="sendEmail"
                            />
                            <button
                              //type="submit"
                              onClick={handleSubmit}
                              //className="button gray btn-block"
                              className="button button-border"
                              disabled={Captcha}
                              style={Captcha ? btndisabled : btnenabled}
                            >
                              <span>Confirm</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    // Thank you message is displayed when isSubmitted is true
                    <div>
                      <h3>
                        <span>Thank you for your confidence, {Nom}.</span>
                      </h3>
                      <br />
                      <h5>
                        We have received your request and you will receive a
                        reply very shortly.
                      </h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Form_en;
