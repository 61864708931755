import React from "react";

const Certifs_Cesi_en = () => {
  const cert50kVA_30kV = "/assets/images/Certificat/cesi/50kVA-30kV.jpg";
  const cert100kVA_10kV = "/assets/images/Certificat/cesi/100kVA-10kV.jpg";
  const cert100kVA_30kV = "/assets/images/Certificat/cesi/100kVA-30kV.jpg";
  const cert160kVA_10kV = "/assets/images/Certificat/cesi/160kVA-10kV.jpg";
  const cert160kVA_30kV = "/assets/images/Certificat/cesi/160kVA-30kV.jpg";
  const cert250kVA_10kV = "/assets/images/Certificat/cesi/250kVA-10kV.jpg";
  const cert250kVA_30kV = "/assets/images/Certificat/cesi/250kVA-30kV.jpg";
  const cert400kVA_10kV = "/assets/images/Certificat/cesi/400kVA-10kV.jpg";
  const cert400kVA_30kV = "/assets/images/Certificat/cesi/400kVA-30kV.jpg";
  const cert630kVA_10kV = "/assets/images/Certificat/cesi/630kVA-10kV.jpg";
  const cert630kVA_30kV = "/assets/images/Certificat/cesi/630kVA-30kV.jpg";

  return (
    <div>
      <section className="shop grid">
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="row">
                {/** 1*/}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-cesi-01-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert50kVA_30kV}
                          alt="50 kVA - 30 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>50 kVA - 30 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>

                {/** 2*/}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-cesi-02-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert100kVA_10kV}
                          alt="100 kVA - 10 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>100 kVA - 10 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}

                {/** 3*/}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-cesi-03-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert100kVA_30kV}
                          alt="100 kVA - 30 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>100 kVA - 30 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** 4*/}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-cesi-04-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert160kVA_10kV}
                          alt="160 kVA - 10 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>160 kVA - 10 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** 5*/}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-cesi-05-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert160kVA_30kV}
                          alt="160 kVA - 30 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>160 kVA - 30 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/**m6 */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-cesi-06-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert250kVA_10kV}
                          alt="250 kVA - 10 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>250 kVA - 10 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** m7 */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-cesi-07-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert250kVA_30kV}
                          alt="250 kVA - 30 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>250 kVA - 30 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** m8 */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-cesi-08-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert400kVA_10kV}
                          alt="400 kVA - 10 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>400 kVA - 10 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/**m9*/}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-cesi-09-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert400kVA_30kV}
                          alt="400 kVA - 30 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>400 kVA - 30 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** m10 */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-10-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert630kVA_10kV}
                          alt="630 kVA - 10 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>630 kVA - 10 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** m11*/}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-11-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert630kVA_30kV}
                          alt="630 kVA - 30 kV certificat"
                        />
                        <br />
                        <br />
                        <h3>630 kVA - 30 kV certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}

                {/**----- */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/** partie modals*/}
      {/**m1 */}
      <div
        className="modal fade bd-cesi-01-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert50kVA_30kV}
                alt="50 kVA - 30 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      {/**m2 */}
      <div
        className="modal fade bd-cesi-02-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert100kVA_10kV}
                alt="100 kVA - 10 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      {/** m3 */}
      <div
        className="modal fade bd-cesi-03-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert100kVA_30kV}
                alt="100 kVA - 30 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      {/** m4*/}
      <div
        className="modal fade bd-cesi-04-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert160kVA_10kV}
                alt="160 kVA - 10 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      {/** m5*/}
      <div
        className="modal fade bd-cesi-05-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert160kVA_30kV}
                alt="160 kVA - 30 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** m6 */}
      <div
        className="modal fade bd-cesi-06-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert250kVA_10kV}
                alt="250 kVA - 10 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** m7*/}
      <div
        className="modal fade bd-cesi-07-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert250kVA_30kV}
                alt="250 kVA - 30 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** m8*/}
      <div
        className="modal fade bd-cesi-08-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert400kVA_10kV}
                alt="400 kVA - 10 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** m9*/}
      <div
        className="modal fade bd-cesi-09-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert400kVA_30kV}
                alt="400 kVA - 30 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/**m10 */}
      <div
        className="modal fade bd-10-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert630kVA_10kV}
                alt="630 kVA - 10 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/**m11 */}
      <div
        className="modal fade bd-11-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert630kVA_30kV}
                alt="630 kVA - 30 kV certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}

      {/** */}
    </div>
  );
};
export default Certifs_Cesi_en;
