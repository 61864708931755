import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Header_en from "./Header_en";
import Footer_en from "./Footer_en";

const Actualites_en = () => {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default action
    console.log("Link click prevented!");
  };
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/actualité/slider-actualite.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>NEWS</h1>
                  <p>What's new at SACEM?</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>news</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 Grid-1 */}
        <div className="page-section-ptb">
          <section className="page-section-pb white-bg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="section-title text-center">
                    <h6>NEWS</h6>
                    <h2 className="title-effect"> SACEM GROUPE</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                {/** actu 1*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche01-01.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche02-02.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche03-03.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ height: "348px" }}>
                      <div
                        className="entry-title mb-10"
                        style={{ height: "60px" }}
                      >
                        <a href="#" onClick={handleClick}>
                          White action for fire-fighting, evacuation and first
                          aid.
                        </a>
                      </div>
                      <div className="entry-meta mb-10">
                        <ul>
                          <li>
                            {" "}
                            <i className="fa fa-folder-open-o" />{" "}
                            <a href="#" onClick={handleClick}>
                              {" "}
                              Factory news{" "}
                            </a>{" "}
                          </li>

                          <li>
                            <a href="#" onClick={handleClick}>
                              <i className="fa fa-calendar-o" /> 02 December
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="entry-content">
                        <p>
                          A look back at the white action for firefighting
                          evacuation and first aid. Thanks to all the team of
                          the civil protection of menzel Bourguiba and to the
                          staff of SACEM GROUP for the smooth running of the
                          action.
                        </p>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Follow us: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/** actu 2*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        {/* <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche01-01.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche02-02.jpg"
                            alt="action blanche SACEM"
                          />
                        </div> */}
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/ACT2.jpg"
                            alt="action blanche SACEM"
                            style={{ maxHeight: "309px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ height: "348px" }}>
                      <div
                        className="entry-title mb-10"
                        style={{ height: "60px" }}
                      >
                        <a href="#" onClick={handleClick}>
                          Rehabilitation of the Tinja public library
                        </a>
                      </div>
                      <div className="entry-meta mb-10">
                        <ul>
                          <li>
                            {" "}
                            <i className="fa fa-folder-open-o" />{" "}
                            <a href="#" onClick={handleClick}>
                              {" "}
                              Factory news{" "}
                            </a>{" "}
                          </li>

                          <li>
                            <a href="#" onClick={handleClick}>
                              <i className="fa fa-calendar-o" /> 27 Septembre
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="entry-content">
                        <p>
                          As part of the company's social responsibility
                          responsibility, and on the instructions of Mr Khaled
                          Kammoun Kammoun, Managing Director of the SACEM Group,
                          a number of were carried out to carry out maintenance
                          and maintenance and repair work to refurbish
                          refurbishment of the Tinja public library.
                        </p>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Follow us: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/** */}

                {/** */}
              </div>
              {/** row 2 */}
              <div className="row">
                {/** actu 1*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/sans-tabac.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/san-tabac2.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ height: "348px" }}>
                      <div className="entry-title mb-10">
                        <a href="#" onClick={handleClick}>
                          World No-Smoking Day
                        </a>
                      </div>
                      <div className="entry-meta mb-10">
                        <ul>
                          <li>
                            {" "}
                            <i className="fa fa-folder-open-o" />{" "}
                            <a href="#" onClick={handleClick}>
                              {" "}
                              Factory news{" "}
                            </a>{" "}
                          </li>

                          <li>
                            <a href="#" onClick={handleClick}>
                              <i className="fa fa-calendar-o" /> 31 Mai 2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="entry-content">
                        <p>
                          To mark World No Tobacco Day on 31 2024, an
                          awareness-raising day was held at the organised at the
                          Sassam Industries factory in Menzel Bourguiba
                          Bourguiba-Tinja for the benefit of workers and
                          employees on the harmful effects of smoking and its
                          negative repercussions health, in collaboration with
                          the regional regional health directorate in Bizerte
                          and the occupational medicine complex in Bizerte.
                        </p>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Follow us: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/** actu 2*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        {/* <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche01-01.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        */}
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/esprit1.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/esprit.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ height: "348px" }}>
                      <div>
                        <div className="entry-title mb-10">
                          <a href="#" onClick={handleClick}>
                            Welcomes students from ESPRIT
                          </a>
                        </div>
                        <div className="entry-meta mb-10">
                          <ul>
                            <li>
                              {" "}
                              <i className="fa fa-folder-open-o" />{" "}
                              <a href="#" onClick={handleClick}>
                                {" "}
                                Factory news{" "}
                              </a>{" "}
                            </li>

                            <li>
                              <a href="#" onClick={handleClick}>
                                <i className="fa fa-calendar-o" /> 08 Mai 2024
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="entry-content">
                          <p>
                            Welcomes students from ESPRIT: École Supérieure
                            École Supérieure d'Ingénierie et de Technologies in
                            our transformer factory. We gave them the
                            opportunity to give them a detailed presentation
                            manufacturing process for the various components of
                            the electrical transformer.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Follow us: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** row 3 */}
              <div className="row">
                {/** actu 1*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Petanque.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Petanque1.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Petanque2.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ height: "348px" }}>
                      <div
                        className="entry-title mb-10"
                        style={{ height: "60px" }}
                      >
                        <a href="#" onClick={handleClick}>
                          First Friendly Tournament Petanque
                        </a>
                      </div>
                      <div className="entry-meta mb-10">
                        <ul>
                          <li>
                            {" "}
                            <i className="fa fa-folder-open-o" />{" "}
                            <a href="#" onClick={handleClick}>
                              {" "}
                              Factory news{" "}
                            </a>{" "}
                          </li>

                          <li>
                            <a href="#" onClick={handleClick}>
                              <i className="fa fa-calendar-o" /> 18 February
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="entry-content">
                        <p>
                          SACEM GROUP would like to thank all the participants
                          of the first friendly petanque tournament on 18
                          February 2024 at the SACEM Sports Complex. It also
                          congratulates the winners of the Home Federation
                          Association and the Tinja Youth Association and wishes
                          them more brilliance in the upcoming competitions.
                        </p>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Follow us: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/** actu 2*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        {/* <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche01-01.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        */}
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/esprit1.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/esprit.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ height: "348px" }}>
                      <div>
                        <div className="entry-title mb-10">
                          <a
                            href="#"
                            onClick={handleClick}
                            style={{ height: "60px" }}
                          >
                            Electricity and Renewable Energies Exhibition ELEK
                            ENER 2023
                          </a>
                        </div>
                        <div className="entry-meta mb-10">
                          <ul>
                            <li>
                              {" "}
                              <i className="fa fa-folder-open-o" />{" "}
                              <a href="#" onClick={handleClick}>
                                {" "}
                                Factory news{" "}
                              </a>{" "}
                            </li>

                            <li>
                              <a href="#" onClick={handleClick}>
                                <i className="fa fa-calendar-o" /> 11 to 15
                                October 2023
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="entry-content">
                          <p>
                            A look back at our participation in the 2nd ELEK
                            ENER 2023 Electricity and Renewable Energy
                            Exhibition, held from 11 to 15 October at the Kram
                            Exhibition Centre in Tunis, with our partner Medelec
                            Switchgear.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Follow us: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** row 3*/}
            </div>
          </section>
        </div>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Actualites_en;
