import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Header_en from "./Header_en";
import Footer_en from "./Footer_en";

const Rse_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          /*style={{ backgroundImage: "url(/assets/images/bg/02.jpg)" }}*/
          style={{
            backgroundImage:
              "url(/assets/images/Entreprise/entreprise-prensentation.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>corporate social responsibility</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>C S R</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/** */}

        <section className="page-section-ptb">
          <div className="container">
            <div
              className="row"
              style={{ textAlign: "justify", padding: "0 15px" }}
            >
              <div className="col-lg-6">
                <img
                  className="img-fluid full-width mb-20"
                  src="/assets/images/about/02.jpg"
                  alt="Responsabilité Sociétale SACEM"
                />
              </div>
              <div className="col-lg-6">
                <div className="section-title">
                  {/* <h6>Know About me </h6> */}
                  <h2 className="title-effect">CSR Commitment</h2>
                  <p>
                    At SACEM Industries, we are committed to sustainable and
                    responsible development.
                  </p>
                </div>
                <p>
                  Building on our heritage since 1966, we integrate
                  environmental, social, and economic considerations into our
                  strategy and operations.
                </p>
                <p>
                  Our CSR commitment is founded on the values of respect,
                  innovation, and transparency, aiming to meet the expectations
                  of our stakeholders while positively contributing to our
                  environment.
                </p>
                {/* <img className="mt-20" src="images/signature.png" alt /> */}
                <cite>
                  <span className="text-gray">The Chief Executive Officer</span>
                  <br />
                  <strong>KHALED KAMMOUN</strong>
                </cite>
              </div>
            </div>
            {/** */}
            <div
              className="row"
              style={{
                padding: "0 15px",
                textAlign: "justify",
                marginTop: "20px",
              }}
            >
              {/* <div className="col-lg-8 col-md-6 mt-50"> */}

              {/* </div> */}
              <div style={{ padding: "0 15px" }}>
                <h3>Our Key Commitments:</h3>
                <h5 className="mt-30">1.Responsible Governance</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>Adopt ethical practices across all our activities.</li>
                  <li>
                    Ensure transparency in our relationships with clients,
                    suppliers, and partners.
                  </li>
                  <li>
                    Continuously train our teams to adhere to international
                    standards.
                  </li>
                </ul>
                <h5 className="mt-30">2. Environmental Protection</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Reduce the ecological footprint of our industrial operations
                    by optimizing energy consumption and minimizing waste.
                  </li>
                  <li>
                    Promote the use of environmentally friendly materials,
                    particularly in our transformers (such as environmentally
                    safe FR3 dielectric oil).
                  </li>
                  <li>
                    Implement recycling solutions for end-of-life components.
                  </li>
                </ul>
              </div>
            </div>
            {/** */}
            <div
              className="row"
              style={{ padding: "0 15px", textAlign: "justify" }}
            >
              <div className="col-lg-8 col-md-6 " style={{ marginTop: "0px" }}>
                <h5 className="mt-30">3. Social and Human Commitment</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Provide safe and inclusive working conditions for our 350
                    employees.
                  </li>
                  <li>
                    Promote personal and professional development through
                    regular training.
                  </li>
                  <li>
                    Actively support local initiatives to foster the
                    socio-economic development of communities surrounding our
                    industrial sites.
                  </li>
                </ul>
                <h5 className="mt-30">
                  4. Respect for the interests of our customers
                </h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Work loyally and fairly for all clients, meeting their needs
                    and expectations while considering our capabilities.
                  </li>
                  <li>
                    Take clients' interests into account by providing commercial
                    offers based on clear, transparent, internally validated
                    pricing that aligns with our cost structure.
                  </li>
                  <li>
                    Maintain the highest quality standards in line with
                    international certifications.
                  </li>
                </ul>
                <h5 className="mt-30">5. Engagement with Stakeholders</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Collaborate with our partners to promote sustainable
                    practices throughout the value chain.
                  </li>
                  <li>
                    Proactively communicate our CSR performance and regularly
                    publish sustainability reports.
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 mt-50 text-white pos-r">
                <div
                  className="action-box small bg-overlay-black-60 parallax"
                  style={{ background: "url(images/bg/06.jpg)" }}
                >
                  <h3 className="text-white">To achieve these objectives</h3>
                  <ul className="list list-arrow">
                    <li>
                      <p>
                        We are committed to establishing clear performance
                        indicators to measure our progress.
                      </p>
                    </li>
                    <li>
                      <p>
                        A CSR committee has been established to plan, oversee,
                        and evaluate the initiatives implemented.
                      </p>
                    </li>
                    <li>
                      <p>
                        We will strengthen dialogue with stakeholders to adapt
                        our actions to evolving needs and expectations.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Rse_en;
