import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";

const Eems = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
    page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{
            backgroundImage: "url(/assets/images/Power/eems.jpg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SEMS/IEMS</h1>
                  <p>Solution SEMS / IEMS</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Acceuil
                    </a>
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>SYSTÈME DE GESTION DE L'ÉNERGIE</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
solution SEMS */}
        <section className="page-section-ptb gray-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h2 className="title-effect" style={{ marginBottom: "40px" }}>
                    Substation Energy Management System – SEMS
                  </h2>
                  <div className="section-title">
                    <p>
                      SEMS est un système de gestion de l'énergie permettant la
                      supervision en temps réel et à distance des postes de
                      transformation HTA/BT, SEMS permet de mesurer, centraliser
                      les données et les événements collectés dans le poste, et
                      d'assurer l'envoi de ces informations à distance vers une
                      plateforme de monitoring.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="img-fluid mx-auto mt-30"
                  src="/assets/images/Power/cofr4.png"
                  alt="SEMS"
                />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-center">
                  <a className="button button-border" href="/contact">
                    Nous Contacter
                  </a>
                  <a
                    className="button button-border"
                    style={{ width: 182 }}
                    href="/assets/brochures/Brochure SS SEMS IEMS septembre 2020 final.pdf"
                  >
                    Brochure
                  </a>
                </div>
              </div>
              <div className="col-lg-6 sm-mt-50 align-self-center">
                <div className="section-title">
                  <h2 className="theme-color">SEMS</h2>
                </div>
                <p>
                  SEMS est un système de gestion de l'énergie permettant la
                  supervision en temps réel et à distance les données de mesure
                  et les évènements des postes de transformation HTA/BT.
                </p>
                <p>Les fonctionnalités du Système SEMS sont les suivantes :</p>
                <div className="row slideInRight wow">
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-signal" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">
                          Surveiller les principaux composants d'un poste
                          HTA/BT.
                        </h5>
                        <p>Environnement :Température du poste</p>
                        <p>
                          Transformateur HTA /BT :Température du transfo
                          <br />
                          Etat d'alarmes DMCR ou DGPT2 ou Buccholz du
                          transformateur.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-layout" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Interface visuel</h5>
                        <p>
                          Mesurer et collecter les principales grandeurs
                          électriques BT sortie transformateur
                          U/V/A/P/Q/S/EA/ER/F/PF.
                        </p>
                        <p>
                          Mesurer et collecter les principaux paramètres
                          d'efficacité énergétique : THDI/THDU/Rangs harmoniques
                          jusqu'au 63ème rang.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-panel" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Efficacité de la gestion</h5>
                        <p>
                          Surveiller ces grandeurs électriques, générer des
                          alarmes ou des événements.
                        </p>
                        <p>
                          Mémoriser, horodater et archiver ces événements et
                          mesures.
                        </p>
                        <p>
                          Relever en local ou à distance via un moyen de
                          communication (GSM/GPRS, Ethernet) les mesures, les
                          alarmes et les évènements vers une plateforme
                          informatique de monitoring «SUBSTATION SMART SOLUTION»
                          pour analyse et supervision.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-package" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Gestion integrée</h5>
                        <p>
                          Envoyer les alarmes et les évènements vers une
                          plateforme informatique de monitoring pour analyse et
                          supervision.
                        </p>
                        <p>
                          Editer des rapports périodiques, editer des tableaux
                          de mesure sous format Excel et visualiser des courbes
                          de charges.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-money" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Application économique</h5>
                        <p>
                          Commander à distance les organes de coupure au niveau
                          cellules HTA et TGBT.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
    solution PLATFORM */}
        {/*=================================
POURQUOI CE SYSTÈME */}
        <section
          id="about-us"
          className="app-home page-section-ptb gray-bg pt-0"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h2 className="theme-color">POURQUOI CE SYSTÈME</h2>
                <br />
                <ul className="list list-unstyled list-hand">
                  <li>
                    Concept de l'internet des objets (IoT), l'interconnexion
                    entre l'Internet et des environnements: monitoring à
                    distance du poste de transformation HTA/BT.
                  </li>
                  <li>
                    Meilleure perception des consommations électriques et des
                    charges des transformateurs.
                  </li>
                  <li>Mise en évidence des déséquilibres de phases.</li>
                  <li>
                    Mise en œuvre de l'ordre de priorité des actions
                    d'efficacité énergétique.
                  </li>
                  <li>Optimisation de la consommation électrique.</li>
                  <li>
                    Conformité avec les prescriptions des normes:{" "}
                    <strong>
                      {" "}
                      CEI 61557-12, CEI 62053-22, CEI 62053-23, CEI 61000-4/-3
                      et CEI 61010-1/-2.
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
POURQUOI CE SYSTÈME */}
        {/*=================================
Solution IEMS */}
        <section className="page-section-ptb gray-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h2 className="theme-color">
                    Industrial Energy Management System – IEMS
                  </h2>
                  <div className="section-title">
                    <p>
                      IEMS est un système de gestion de l'énergie permettant la
                      supervision en temps réel et à distance des départs TGBT,
                      IEMS permet de mesurer, centraliser les données et les
                      événements collectés dans les départs électriques, et
                      d'assurer l'envoi de ces informations à distance vers une
                      plateforme de monitoring.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="img-fluid mx-auto mt-30"
                  src="/assets/images/Power/cofr4.png"
                  alt="IEMS"
                />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-center">
                  <a className="button button-border" href="/contact">
                    Nous Contacter
                  </a>
                  <a
                    className="button button-border"
                    style={{ width: 182 }}
                    href="/assets/brochures/Brochure SS SEMS IEMS septembre 2020 final.pdf"
                  >
                    Brochure
                  </a>
                </div>
              </div>
              <div className="col-lg-6 sm-mt-50 align-self-center">
                <div className="section-title">
                  <h2 className="title-effect" style={{ color: "#00356a" }}>
                    IEMS
                  </h2>
                </div>
                <p>
                  IEMS est un système de gestion de l'énergie permettant la
                  supervision en temps réel et à distance les données de mesure
                  et les évènements des tableaux de distribution électriques tel
                  que Tableau Général Basse Tension TGBT - Armoires électrique.
                </p>
                <p>Les fonctionnalités du Système IEMS sont les suivantes :</p>
                <div className="row slideInRight wow">
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-signal" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Gestion à distance</h5>
                        <p>
                          Relever en local ou à distance via un moyen de
                          communication (GSM/GPRS, Ethernet) les mesures, les
                          alarmes et les évènements vers une plateforme
                          informatique de monitoring «FACTORY SMART SOLUTION»
                          pour analyse et supervision.
                        </p>
                        <p>
                          Commander à distance les organes de coupure au niveau
                          du disjoncteur principal du TGBT.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-layout" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Interface visuel</h5>
                        <p>
                          Editer des rapports périodiques, editer des tableaux
                          de mesure sous format Excel et visualiser des courbes
                          des charges de chaque départ du TGBT.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-panel" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Efficacité de la gestion</h5>
                        <p>
                          Mesurer et collecter les principales grandeurs
                          électriques BT départs électriques
                          U/V/A/P/Q/S/EA/ER/F/PF.
                        </p>
                        <p>
                          Mesurer et collecter les principaux paramètres
                          d'efficacité énergétique : THDI/THDU/Rangs harmoniques
                          jusqu'au 63ème rang.
                        </p>
                        <p />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-package" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Gestion integrée</h5>
                        <p>
                          Surveiller ces grandeurs électriques, générer des
                          alarmes ou des événements Mémoriser, horodater et
                          archiver ces événements et mesures de chaque départ
                          électrique.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
    solution IEMS */}
        {/*=================================
POURQUOI CE SYSTÈME IEMS*/}
        <section
          id="about-us"
          className="app-home page-section-ptb gray-bg pt-0"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h2 className="theme-color">POURQUOI CE SYSTÈME</h2>
                <br />
                <ul className="list list-unstyled list-hand">
                  <li>
                    Concept de l'internet des objets (IoT), l'interconnexion
                    entre l'Internet et des environnements physiques.
                  </li>
                  <li>Monitoring à distance des départs.</li>
                  <li>
                    Meilleure perception des consommations électriques et des
                    charges des différent départs.
                  </li>
                  <li>Mise en évidence des déséquilibres de phases.</li>
                  <li>
                    Mise en œuvre de l'ordre de priorité des actions
                    d'efficacité énergétique.
                  </li>
                  <li>Optimisation de la consommation électrique.</li>
                  <li>
                    Conformité avec les prescriptions des normes:{" "}
                    <strong>
                      {" "}
                      CEI 61557-12, CEI 62053-22, CEI 62053-23, CEI 61000-4/-3
                      et CEI 61010-1/-2.
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
POURQUOI CE SYSTÈME IEMS*/}
        {/*=================================
solution detail */}
        <section className="our-services page-section-ptb gray-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-title text-center">
                  <h2 className="title-effect">CARACTÉRISTIQUES</h2>
                  <h6>
                    Les principales fonctionnalités de SMART TRANSFORMER
                    platform permettent de:
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="feature-text box-shadow text-center mb-30 white-bg slideInUp wow">
                  <div className="feature-icon">
                    <span
                      aria-hidden="true"
                      className="ti-settings theme-color-cyan"
                    />
                  </div>
                  <div className="fature-info">
                    <h4>Descriptives techniques des Systèmes SEMS/IEMS</h4>
                    <br />
                    <button
                      className="button button-border"
                      data-toggle="modal"
                      data-target=".bd-01-modal-lg"
                    >
                      Détails
                    </button>

                    <div
                      className="modal fade bd-01-modal-lg"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="modal-title" id="01ModalLongTitle">
                              <div className="section-title mb-10">
                                <h3>SEMS/IEMS</h3>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Fermer"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body text-justify">
                            <h4>
                              Descriptives techniques des Systèmes SEMS/IEMS
                            </h4>
                            {/* <img
                              className="img-fluid mx-auto mt-20"
                              src="/assets/images/Power/01.jpg"
                              alt
                            /> */}
                            <p className="mt-20">
                              Les centrales de mesure SACEM SMART sont logées à
                              l'intérieur de coffret IP 54 pour former un
                              équipement de mesure parfaitement adapté à son
                              environnement.
                            </p>
                            <p>
                              Pour rendre l'installation des coffrets simple
                              tout en garantissant une sureté maximum, les
                              centrales de mesure intègrent des connectiques
                              “plug and play” dédiées à chaque fonctionnalité :
                            </p>
                            <ul className="list list-unstyled list-hand">
                              <li>
                                Entrées secondaires TC (mesure de courant).
                              </li>
                              <li>Entrées Tension BT et Alimentation.</li>
                              <li>
                                Sortie liaison de communication Modbus TCP/IP.
                              </li>
                              <li>Entrées capteur de température Pt100.</li>
                              <li>Entrées capteur digitales.</li>
                              <li>Capteur sorties digitales.</li>
                              <li>Capteur entrées Analogique 4-20 mA.</li>
                            </ul>
                            <h4 className="mt-30">Communication</h4>
                            <img
                              className="img-fluid mx-auto mt-20"
                              src="/assets/images/Power/communication.PNG"
                              alt="Systèmes SEMS/IEMS"
                            />
                            <p className="mt-20">
                              Nos systèmes de gestion SEMS/IEMS permettent la
                              communication par :
                            </p>
                            <ul className="list list-arrow">
                              <li>
                                Un port Ethernet (prise RJ45) permettant le
                                raccordement à un modem-routeur ou un switch
                                présent dans le poste, pour une connexion
                                internet ou/et intranet.
                              </li>
                              <li>Un modem GSM/GPRS.</li>
                            </ul>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Fermer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="feature-text box-shadow text-center mb-30 white-bg slideInUp wow">
                  <div className="feature-icon">
                    <span
                      aria-hidden="true"
                      className="ti-bolt theme-color-cyan"
                    />
                  </div>
                  <div className="fature-info">
                    <h4>Architecture du système</h4>
                    <br />
                    <br />
                    <button
                      className="button button-border"
                      data-toggle="modal"
                      data-target=".bd-02-modal-lg"
                    >
                      Détails
                    </button>

                    <div
                      className="modal fade bd-02-modal-lg"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="modal-title" id="02ModalLongTitle">
                              <div className="section-title mb-10">
                                <h3 />
                              </div>
                            </div>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Fermer"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body text-justify">
                            <h4>Architecture du système</h4>
                            <img
                              className="img-fluid mx-auto mt-20"
                              src="/assets/images/Power/Architectures-EEMS.PNG"
                              alt="Architecture du système"
                            />
                            <br />
                            <br />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Fermer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="feature-text box-shadow text-center mb-30 white-bg slideInUp wow">
                  <div className="feature-icon">
                    <span
                      aria-hidden="true"
                      className="ti-package theme-color-cyan"
                    />
                  </div>
                  <div className="fature-info">
                    <h4>Caractéristiques techniques</h4>
                    <button
                      className="button button-border"
                      data-toggle="modal"
                      data-target=".bd-03-modal-lg"
                    >
                      Détails
                    </button>

                    <div
                      className="modal fade bd-03-modal-lg"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="modal-title" id="01ModalLongTitle">
                              <div className="section-title mb-10">
                                <h3></h3>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Fermer"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body text-justify">
                            <h4>
                              Les centrales de mesure des systèmes de gestion de
                              l'électricité
                            </h4>
                            <p>
                              Nos centrales de mesures SACEM SMART peuvent
                              collecter les données électriques suivantes :
                            </p>
                            <ul className="list list-hand">
                              <li>Les tensions BT.</li>
                              <li>Les courants BT:</li>
                            </ul>
                            <ul
                              className="list list-arrow"
                              style={{ marginLeft: "20px" }}
                            >
                              <li>Courants de charge (phases).</li>
                              <li>
                                Courant de déséquilibre (courant de neutre
                                calculé).
                              </li>
                            </ul>
                            <ul className="list list-hand">
                              <li>
                                Les puissances actives, réactives et apparentes.
                              </li>
                              <li>L'énergie active et réactive.</li>
                              <li>Le facteur de puissance.</li>
                              <li>
                                La fréquence, les taux de perturbation
                                harmonique (THD, facteur de distorsion).
                              </li>
                            </ul>
                            <img
                              className="img-fluid mx-auto mt-20"
                              src="/assets/images/Power/Cartestiques.PNG"
                              alt="Caractéristiques techniques"
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Fermer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="feature-text box-shadow text-center mb-30 white-bg slideInUp wow">
                  <div className="feature-icon">
                    <span
                      aria-hidden="true"
                      className="ti-shield theme-color-cyan"
                    />
                  </div>
                  <div className="fature-info">
                    <h4>Plateforme informatique de monitoring</h4>
                    <button
                      className="button button-border"
                      data-toggle="modal"
                      data-target=".bd-04-modal-lg"
                    >
                      Détails
                    </button>

                    <div
                      className="modal fade bd-04-modal-lg"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="modal-title" id="04ModalLongTitle">
                              <div className="section-title mb-10">
                                <h3 style={{ color: "#00356a" }}>
                                  SUBSTATION SMART SOLUTION
                                </h3>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Fermer"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body text-justify">
                            <p className="mt-20">
                              <strong>SUBSTATION SMART SOLUTION</strong>, c'est
                              une plateforme informatique permettant la
                              visualisation en temps réel et à distance les
                              données de mesure et les évènements survenus au
                              poste de transformation HTA/BT. Cette plateforme
                              permet
                            </p>
                            <div className="content-wrapper-substation">
                              <ul
                                className="column-list-substation list list-arrow"
                                style={{ wordBreak: "break-all" }}
                              >
                                <li>La génération des rapports périodiques.</li>
                                <li>
                                  La génération des tableaux de mesure <br />{" "}
                                  sous format Excel.
                                </li>
                                <li>
                                  La visualisation des états de
                                  fonctionnem&shy;ent des équipements.
                                </li>
                                <li>
                                  La visualisation des courbes relatives aux
                                  paramètres mesurés.
                                </li>
                                <li>
                                  L'enregistrement et l'archivage des
                                  événe&shy;ments et des mesures.
                                </li>
                                <li>Le paramétrage des seuils d'alarme.</li>
                                <li>
                                  La Commande à distance des organes de coupure
                                  (en option).
                                </li>
                              </ul>
                              <img
                                className="img-fluid-substation mx-auto mt-20"
                                src="/assets/images/Power/substation-solution.PNG"
                                alt="SUBSTATION SMART SOLUTION"
                              />
                            </div>
                            <br />
                            <br />
                            <div className="divider double" />
                            <br />
                            <br />
                            <div className="section-title mb-10">
                              <h3
                                className="title-effect"
                                style={{ color: "#00356a" }}
                              >
                                FACTORY SMART SOLUTION
                              </h3>
                            </div>
                            <div className="content-wrapper-substation">
                              <ul
                                className="column-list-substation list list-arrow"
                                style={{ wordBreak: "break-all" }}
                              >
                                <li>
                                  La génération des rapports <br /> périodiques.
                                </li>
                                <li>
                                  La génération des tableaux de <br /> mesure
                                  sous format Excel.
                                </li>
                                <li>
                                  La visualisation des états de fonctionnem-ent
                                  des départs électriques.
                                </li>
                                <li>
                                  La visualisation des courbes relatives aux
                                  paramètres mesurés de chaque départ.
                                </li>
                                <li>
                                  L'enregistrement et l'archivage des
                                  événe-ments et des mesures.
                                </li>
                                <li>
                                  Le paramétrage des seuils d'alarme de <br />{" "}
                                  chaque départ.
                                </li>
                                <li>
                                  L'intégration d'autres paramètres non <br />
                                  électrique (en option).
                                </li>
                                <li>
                                  La Commande à distance des organes de coupure
                                  (en option).
                                </li>
                              </ul>
                              <img
                                className="img-fluid-substation mx-auto mt-20"
                                src="/assets/images/Power/Factory_substation.png"
                                alt="FACTORY SMART SOLUTION"
                                style={{ maxWidth: "350px" }}
                              />
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Fermer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
solution detail */}
      </div>
      <div>
        <Footer />{" "}
      </div>
    </div>
  );
};

export default Eems;
