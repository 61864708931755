import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import Loader from "./Loader";
const Fortest2 = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  const Textstyle = {
    fontSize: "22px",
    lineHeight: "28px",
    fontWeight: "500",
    color: "#222c5a",
  };
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;

  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
    </div>
  );
};
export default Fortest2;
