import React from "react";

const Certifs_Veiki_Vnl = () => {
  const cert300kVA_VEIKI_VNL =
    "/assets/images/Certificat/300 kVA VEIKI-VNL certificate.png";
  const cert1500kVA_VEIKI_VNL =
    "/assets/images/Certificat/1500 kVA VEIKI-VNL certificate.png";

  return (
    <div>
      <section className="shop grid ">
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="row">
                {/** */}

                {/** */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-vnl-03-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert300kVA_VEIKI_VNL}
                          alt="300 kVA VEIKI-VNL certificate"
                        />
                        <br />
                        <br />
                        <h3>300 kVA VEIKI-VNL certificate</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}

                {/** */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-vnl-07-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert1500kVA_VEIKI_VNL}
                          alt="1500 kVA VEIKI-VNL certificate"
                        />
                        <br />
                        <br />
                        <h3>1500 kVA VEIKI-VNL certificate</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}

                {/** */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/** */}
      {/** */}
      <div
        className="modal fade bd-vnl-03-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert300kVA_VEIKI_VNL}
                alt="300 kVA VEIKI VNL"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}

      <div
        className="modal fade bd-vnl-07-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert1500kVA_VEIKI_VNL}
                alt="1500 kVA VEIKI VNL"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}

      {/** */}
    </div>
  );
};

export default Certifs_Veiki_Vnl;
