import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Notfoundpage = () => {
  return (
    <div>
      <Header />
      <section className="page-section-ptb" style={{ paddingTop: "180px" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <div className="error-block text-center clearfix">
                <div className="error-text">
                  <h2>404</h2>
                  <span>Error </span>
                </div>
                <h1 className="theme-color mb-40">Ooopps :(</h1>
                <p>La page que vous cherchiez est introuvable.</p>
              </div>
              <div className="error-info">
                <p className="mb-50">
                  La page que vous recherchez a peut-être été supprimée, son nom
                  a changé ou elle est temporairement indisponible.
                </p>
                <a className="button xs-mb-10 " href="/">
                  Acceuil
                </a>
                <a className="button button-border black" href="/Contact">
                  Nous Contacter
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Notfoundpage;
