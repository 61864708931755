import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";

const Batterie_Condensateur = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{ backgroundImage: "url(/assets/images/slidebat.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Batterie de Condensateurs</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Acceuil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Batterie de Condensateurs</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section style={{ paddingTop: "80px" }}>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2 className="title-effect">Batterie de Condensateurs</h2>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                <div className="row mt-70">
                  <div className="col-lg-12 col-md-12">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/bat-01.png"
                          alt="BATTERIE_DE_CONDENSATEUR"
                        />
                      </div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/Bat-02.png"
                          alt="BATTERIE_DE_CONDENSATEUR"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 sm-mt-50 align-self-center">
                <div
                  className="bounceIn wow"
                  style={{
                    visibility: "visible",
                    animationName: "bounceIn",
                  }}
                >
                  <div className="section-title ">
                    <h3 style={{ color: "#00356a" }}>
                      POURQUOI UNE BATTERIE DE CONDENSATEURS ?
                    </h3>
                    <br />
                    <br />
                    <p>
                      Une batterie de condensateurs est essentielle pour
                      améliorer la qualité de l'énergie, réduire les pertes,
                      optimiser l'efficacité du réseau électrique et corriger le
                      cos φ et le facteur de puissance.
                    </p>
                    <br />

                    <p>
                      Dans notre gamme, nous proposons des batteries de
                      condensateurs fixes et automatiques, avec ou sans filtre
                      anti-harmonique, adaptées aux différents besoins
                      d'optimisation énergétique et de protection du réseau.
                    </p>

                    <br />
                    <br />
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div style={{ paddingRight: "15px" }}>
                        <a className="button button-border" href="/Contact">
                          Nous Contacter
                        </a>
                      </div>
                      <div style={{ paddingLeft: "15px" }}>
                        <a
                          className="button button-border"
                          href="/assets/brochures/CATALOGUE BATTERIE DE CONDENSATEURS.PDF"
                        >
                          <span>Catalogue</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider double"></div>
            <div>
              <section className="service white-bg page-section-ptb">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <div className="section-title text-center">
                        <h6> </h6>
                        <h2 className="title-effect">Nos Gammes de Batterie</h2>
                        <p />
                      </div>
                    </div>
                  </div>
                  {/* =========================================== */}
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 mb-30">
                      <div
                        className="media border h-100 p-4"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <span className="media-step theme-color mr-4">
                          SCEFix
                        </span>
                        <div className="media-body">
                          <h4 style={{ height: "52px" }}>
                            Batterie Compensation Fixe
                          </h4>

                          <div className="fature-info">
                            <button
                              className="button button-border"
                              data-toggle="modal"
                              data-target=".bd-01-modal-lg"
                            >
                              Détails
                            </button>

                            <div
                              className="modal fade bd-01-modal-lg"
                              tabIndex={-1}
                              role="dialog"
                              aria-labelledby="myLargeModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <div
                                      className="modal-title"
                                      id="01ModalLongTitle"
                                    >
                                      <div className="section-title mb-10"></div>
                                    </div>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Fermer"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div className="modal-body text-justify">
                                    <h4>SCEFix</h4>
                                    <div className="modal-body text-justify">
                                      <h4>Nos gammes des produits SCEFix</h4>
                                      <ul className="list list-hand">
                                        <li>
                                          <h6>SCEFix-N:</h6>Compensation fixe
                                          pour réseau non pollué par
                                          condensateur standard type N.
                                        </li>
                                        <br />
                                        <li>
                                          <h6>SCEFix-H:</h6> Compensation fixe
                                          pour réseau peu pollué par
                                          condensateur renforcé type HD.
                                        </li>
                                        <br />
                                        <li>
                                          <h6>SCEFix-F:</h6>Compensation fixe
                                          pour réseau pollué:{" "}
                                        </li>
                                      </ul>
                                      <ul className=" list list-arrow">
                                        <li>
                                          SCEFix-FN : Condensateur standard type
                                          N et filtre anti-harmonique 7%{" "}
                                        </li>
                                        <li>
                                          SCEFix-FH : Condensateur renforcé type
                                          HD et filtre anti-harmonique 7%
                                        </li>
                                        <li>
                                          SCEFix-FNS : Condensateur standard
                                          type N et filtre anti-harmonique 14%
                                        </li>
                                        <li>
                                          SCEFix-FHS : Condensateur renforcé
                                          type HD et filtre anti-harmonique 14%
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <a
                                      className="button button-border"
                                      href="/Contact"
                                    >
                                      Nous Contacter
                                    </a>

                                    <a
                                      className="button button-border"
                                      href="/assets/brochures/CATALOGUE BATTERIE DE CONDENSATEURS.PDF"
                                    >
                                      <span>Catalogue</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 mb-30">
                      <div
                        className="media border h-100 p-4"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <span className="media-step theme-color mr-4">
                          SCEVar
                        </span>
                        <div className="media-body">
                          <h4 style={{ height: "52px" }}>
                            Batterie Compensation Automatique
                          </h4>
                          <div className="fature-info">
                            <button
                              className="button button-border"
                              data-toggle="modal"
                              data-target=".bd-02-modal-lg"
                            >
                              Détails
                            </button>

                            <div
                              className="modal fade bd-02-modal-lg"
                              tabIndex={-1}
                              role="dialog"
                              aria-labelledby="myLargeModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <div
                                      className="modal-title"
                                      id="02ModalLongTitle"
                                    >
                                      <div className="section-title mb-10">
                                        <h3>SCEVar</h3>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Fermer"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div className="modal-body text-justify">
                                    <h4>Nos gammes des produits SCEVar</h4>
                                    <ul className="list list-hand">
                                      <li>
                                        <h6>SCEVar-N:</h6>Compensation
                                        automatique pour réseau non pollué par
                                        condensateur standard type N.
                                      </li>
                                      <br />
                                      <li>
                                        <h6>SCEVar-H:</h6>Compensation
                                        automatique pour réseau peu pollué par
                                        condensateur renforcé type HD.
                                      </li>
                                      <br />
                                      <li>
                                        <h6>SCEVar-F:</h6>Compensation
                                        automatique pour réseau pollué:{" "}
                                      </li>
                                    </ul>
                                    <ul className=" list list-arrow">
                                      <li>
                                        SCEVar-FN : Condensateur standard type N
                                        et filtre anti-harmonique 7%
                                      </li>
                                      <li>
                                        SCEVar-FH : Condensateur renforcé type
                                        HD et filtre anti-harmonique 7%
                                      </li>
                                      <li>
                                        SCEVar-FNS : Condensateur standard type
                                        N et filtre anti-harmonique 14%
                                      </li>
                                      <li>
                                        SCEVar-FHS : Condensateur renforcé type
                                        HD et filtre anti-harmonique 14%
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="modal-footer">
                                    <a
                                      className="button button-border"
                                      href="/Contact"
                                    >
                                      Nous Contacter
                                    </a>
                                    <a
                                      className="button button-border"
                                      href="/assets/brochures/CATALOGUE BATTERIE DE CONDENSATEURS.PDF"
                                    >
                                      <span>Catalogue</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Batterie_Condensateur;
