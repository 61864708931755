import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "./Footer";
import Loader from "./Loader";
import Header from "./Header";

const Transformateur = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/transformateur/Triphase/slider-triphase-36-24.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>Innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home"></i> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right"></i>
                  </li>
                  <li>
                    <span>Transformateur</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/*=================================
page-title */}
        {/*=================================
 feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Nos Gammes de Transformateurs
                  </h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            <div className="service-3">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div
                      className="service-blog text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">
                        Transformateurs de distribution triphasés
                      </h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Nos transformateurs de distribution triphasés sont
                        conçus pour garantir une distribution d’énergie fiable
                        et efficace. Adaptés aux systèmes électriques
                        industriels, commerciaux et résidentiels, ils
                        transforment la haute tension en basse tension, assurant
                        une alimentation sécurisée et stable.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>Transformateur triphasé immergé dans l’huile</li>
                        <li>Remplissage intégral, hermétique</li>
                        <li>Refroidissement naturel type ONAN</li>
                        <li>Installation intérieure/extérieure</li>
                        <li>
                          Couleur: gris clair - RAL 7035 (autre sur demande)
                        </li>
                      </ul>
                    </div>

                    <div className="entry-button">
                      <a
                        className="button arrow"
                        href="/transformateur_dist_36"
                      >
                        Lire plus
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/contact"
                      >
                        Nous Contacter
                      </a>
                    </div>
                  </div>

                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div className="col-lg-12 col-md-12">
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Distrubition/Transfo-DST1.png"
                          alt="Transformateurs de distribution triphasés"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div>
                      <img
                        className="img-fluid"
                        src="/assets/images/transformateur/Puissance/20P2-Photoroom.png"
                        alt="Transformateurs de puissance Triphasés"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="section-title slideInRight wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInRight",
                    }}
                  >
                    <div
                      className="service-blog left text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">
                        Transformateurs de puissance Triphasés
                      </h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Nos transformateurs de puissance triphasés sont conçus
                        pour répondre aux exigences les plus strictes en matière
                        de distribution d'énergie électrique, garantissant une
                        performance optimale, une efficacité énergétique élevée
                        et une fiabilité sur le long terme.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderLeft: "0px solid white" }}
                      >
                        <li>Transformateur triphasé immergé dans l’huile</li>
                        <li>Refroidissement ONAN / ONAF</li>
                        <li>Installation intérieure/Extérieure</li>
                        <li>Norme CEI 60076</li>
                      </ul>
                    </div>
                    <div className="entry-button">
                      <a
                        className="button arrow"
                        href="/transformateur_pui_triph"
                      >
                        Lire plus
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/contact"
                      >
                        Nous Contacter
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div
                      className="service-blog text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">
                        Transformateurs Monophasés
                      </h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Découvrez notre gamme de transformateurs monophasés,
                        conçus pour répondre à vos besoins en alimentation
                        électrique avec fiabilité et performance.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>Transformateur monophasé immergé dans l’huile</li>
                        <li>Refroidissement naturel type ONAN</li>
                        <li>Installation intérieure/extérieure</li>
                        <li>
                          Couleur: gris clair - RAL 7035 (autre sur demande)
                        </li>
                      </ul>
                    </div>
                    <div className="entry-button">
                      <a className="button arrow" href="/transformateur_mono">
                        Lire plus
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/contact"
                      >
                        Nous Contacter
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div className="col-lg-12 col-md-12">
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Mono/MONO.png"
                          alt="Transformateurs Monophasés"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div>
                      <img
                        className="img-fluid"
                        src="/assets/images/transformateur/Specials/special.png"
                        alt="Transformateurs Spéciaux"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="section-title slideInRight wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInRight",
                    }}
                  >
                    <div
                      className="service-blog left text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">Transformateurs Spéciaux</h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Notre gamme de production inclut également des
                        transformateurs spéciaux pour des applications
                        spécifiques.
                        <br />
                        Notre équipe de conception expérimentée est en mesure de
                        répondre à vos besoins en matière de transformateurs
                        avec des dimensions spécifiques ou des configurations
                        non standard.
                      </p>

                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderLeft: "0px solid white" }}
                      >
                        <li>Transformateurs de mise à la terre</li>
                        <li>Autotransformateur</li>
                        <li>Transformateur d'isolement</li>
                      </ul>
                    </div>
                    <div className="entry-button">
                      <a
                        className="button arrow"
                        href="/transformateur_special"
                      >
                        Lire plus
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/contact"
                      >
                        Nous Contacter
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              {/* ============================================ */}
            </div>
            <div className="row mt-70">
              <div className="col-lg-12 col-md-12">
                <h2 className="tile-headline">Nos Certificats </h2>
                <StyledHeading></StyledHeading>
                {/**<div className="divider double" />*/}
                <br />
                <br />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="clients-list">
                <div
                  className="owl-carousel"
                  data-nav-dots="false"
                  data-items={3}
                  data-md-items={4}
                  data-sm-items={3}
                  data-xs-items={2}
                  data-xx-items={2}
                >
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/cesi.png"
                      alt="cesi"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/veiki.png"
                      alt="veiki"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/kema.png"
                      alt="kema"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Transformateur;
