import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Header_en from "./Header_en";
import Footer_en from "./Footer_en";

const Batterie_Condensateur_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{ backgroundImage: "url(/assets/images/slidebat.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Battery of Capacitors</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Battery of Capacitors</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section style={{ paddingTop: "80px" }}>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2 className="title-effect">Battery of Capacitors</h2>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 sm-mt-50 align-self-center">
                <div
                  className="bounceIn wow"
                  style={{
                    visibility: "visible",
                    animationName: "bounceIn",
                  }}
                >
                  <div className="section-title ">
                    <h3 style={{ color: "#00356a" }}>
                      WHY USE A CAPACITOR BANK?
                    </h3>
                    <br />
                    <br />
                    <p>
                      A capacitor bank is essential for improve power quality
                      and reduce losses, optimise the efficiency of the
                      electrical network and correct the cos φ and power factor.
                    </p>
                    <br />

                    <p>
                      Our range includes capacitor banks and automatic capacitor
                      banks, with or without anti-harmonic filter, adapted to
                      the different needs of energy energy optimisation and
                      network protection.
                    </p>

                    <br />
                    <br />
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div style={{ paddingRight: "15px" }}>
                        <a className="button button-border" href="/en/contact">
                          Contact us
                        </a>
                      </div>
                      <div style={{ paddingLeft: "15px" }}>
                        <a
                          className="button button-border"
                          href="/assets/brochures/CATALOGUE BATTERIE DE CONDENSATEURS.PDF"
                        >
                          <span>Catalogue</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                <div className="row mt-70">
                  <div className="col-lg-12 col-md-12">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/bat-01.png"
                          alt="BATTERIE_DE_CONDENSATEUR"
                        />
                      </div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/Bat-02.png"
                          alt="BATTERIE_DE_CONDENSATEUR"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider double"></div>
            <div>
              <section className="service white-bg page-section-ptb">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <div className="section-title text-center">
                        <h6> </h6>
                        <h2 className="title-effect">Our range of Batteries</h2>
                        <p />
                      </div>
                    </div>
                  </div>
                  {/* =========================================== */}
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 mb-30">
                      <div
                        className="media border h-100 p-4"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <span className="media-step theme-color mr-4">
                          SCEFix
                        </span>
                        <div className="media-body">
                          <h4 style={{ height: "52px" }}>
                            Fixed compensation battery
                          </h4>
                          <p className="mb-0" />
                          <div className="fature-info">
                            <button
                              className="button button-border"
                              data-toggle="modal"
                              data-target=".bd-01-modal-lg"
                            >
                              Details
                            </button>

                            <div
                              className="modal fade bd-01-modal-lg"
                              tabIndex={-1}
                              role="dialog"
                              aria-labelledby="myLargeModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <div
                                      className="modal-title"
                                      id="01ModalLongTitle"
                                    >
                                      <div className="section-title mb-10"></div>
                                    </div>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Fermer"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div className="modal-body text-justify">
                                    <h4>SCEFix</h4>
                                    <div className="modal-body text-justify">
                                      <h4>Our ranges of SCEFix products</h4>
                                      <ul className="list list-hand">
                                        <li>
                                          <h6>SCEFix-N:</h6>Fixed compensation
                                          for unpolluted network with standard
                                          N-type capacitor.
                                        </li>
                                        <br />
                                        <li>
                                          <h6>SCEFix-H:</h6> Fixed compensation
                                          for lightly polluted networks HD type
                                          reinforced capacitor.
                                        </li>
                                        <br />
                                        <li>
                                          <h6>SCEFix-F:</h6>Fixed compensation
                                          for polluted networks:{" "}
                                        </li>
                                      </ul>
                                      <ul className=" list list-arrow">
                                        <li>
                                          SCEFix-FN : Standard capacitor type N
                                          and anti-harmonic filter 7%{" "}
                                        </li>
                                        <li>
                                          SCEFix-FH : Reinforced capacitor type
                                          HD and anti-harmonic filter 7%
                                        </li>
                                        <li>
                                          SCEFix-FNS : Standard capacitor type N
                                          and anti-harmonic filter 14%
                                        </li>
                                        <li>
                                          SCEFix-FHS : Reinforced capacitor HD
                                          type and anti-harmonic filter 14%
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <a
                                      className="button button-border"
                                      href="/en/contact"
                                    >
                                      Contact us
                                    </a>

                                    <a
                                      className="button button-border"
                                      href="/assets/brochures/CATALOGUE BATTERIE DE CONDENSATEURS.PDF"
                                    >
                                      <span>Catalogue</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 mb-30">
                      <div
                        className="media border h-100 p-4"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <span className="media-step theme-color mr-4">
                          SCEVar
                        </span>
                        <div className="media-body">
                          <h4 style={{ height: "52px" }}>
                            Battery Automatic Compensation
                          </h4>
                          <div className="fature-info">
                            <button
                              className="button button-border"
                              data-toggle="modal"
                              data-target=".bd-02-modal-lg"
                            >
                              Details
                            </button>

                            <div
                              className="modal fade bd-02-modal-lg"
                              tabIndex={-1}
                              role="dialog"
                              aria-labelledby="myLargeModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <div
                                      className="modal-title"
                                      id="02ModalLongTitle"
                                    >
                                      <div className="section-title mb-10">
                                        <h3>SCEVar</h3>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Fermer"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div className="modal-body text-justify">
                                    <h4>Our ranges of SCEVar products</h4>
                                    <ul className="list list-hand">
                                      <li>
                                        <h6>SCEVar-N:</h6>Compensation automatic
                                        compensation for unpolluted network
                                        standard N-type capacitor.
                                      </li>
                                      <br />
                                      <li>
                                        <h6>SCEVar-H:</h6>Compensation automatic
                                        compensation for slightly polluted HD
                                        type reinforced capacitor.
                                      </li>
                                      <br />
                                      <li>
                                        <h6>SCEVar-F:</h6>Compensation for
                                        polluted networks:{" "}
                                      </li>
                                    </ul>
                                    <ul className=" list list-arrow">
                                      <li>
                                        SCEVar-FN : Standard N-type capacitor
                                        and anti-harmonic filter 7%
                                      </li>
                                      <li>
                                        SCEVar-FH : Reinforced capacitor type HD
                                        and anti-harmonic filter 7%
                                      </li>
                                      <li>
                                        SCEVar-FNS : Standard capacitor type N
                                        and anti-harmonic filter 14%
                                      </li>
                                      <li>
                                        SCEVar-FHS : Reinforced capacitor type
                                        HD and anti-harmonic filter 14%
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="modal-footer">
                                    <a
                                      className="button button-border"
                                      href="/en/contact"
                                    >
                                      Contact us
                                    </a>
                                    <a
                                      className="button button-border"
                                      href="/assets/brochures/CATALOGUE BATTERIE DE CONDENSATEURS.PDF"
                                    >
                                      <span>Catalogue</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Batterie_Condensateur_en;
