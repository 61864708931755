import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";

const Produits = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{ backgroundImage: "url(/assets/images/bg/1920-1000.jpg)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Produit SACEM</h1>
                  <p>We know the secret of your success</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Produit</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 lists-panels */}
        {/**section 1 */}
        <section style={{ paddingTop: "80px" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">Nos Produits</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h4 className="mb-30" style={{ color: "black" }}>
                  Nous disposons d'une vaste gamme de produits, systèmes,
                  services et solutions pour des clients issus d'un grand nombre
                  de secteurs. Vous pouvez les explorer par secteur ci-dessous.
                </h4>
              </div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/transformateurs.png"
                    alt="Transformateur électrique SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "420px" }}>
                    <h5 className="card-title">Transformateur électrique</h5>
                    <br />
                    <ul className="list list-hand">
                      <li>
                        Transformateurs de distribution triphasés jusqu'à la
                        classe 36 kV et 3000 kVA.
                      </li>
                      <li>
                        Transformateurs de puissance triphasés jusqu'à la classe
                        36 kV et 40 MVA.
                      </li>
                      <li>
                        Transformateurs de distribution monophasés jusqu'à la
                        classe 36 kV et 210 kVA.
                      </li>
                      <li>Transformateurs Spéciaux.</li>
                    </ul>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/transformateur" className="button button-border">
                      Détail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/cellules AIS.png"
                    alt="Tableau moyenne tension 36 kV SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "420px" }}>
                    <h5 className="card-title">
                      Tableau moyenne tension 36 kV
                    </h5>
                    <br />

                    <ul className="list list-hand">
                      <li>Cellule réseau (Arrivée/Départ).</li>
                      <li>Cellule de protection transformateur par fusible.</li>
                      <li>Cellule de mesure tension.</li>

                      <li>Cellule disjoncteur double sectionnement.</li>
                    </ul>
                    <p />
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/tableauais" className="button button-border">
                      Détail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/batterie.png"
                    alt="Batterie de condensateurs SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "420px" }}>
                    <h5 className="card-title">Batterie de condensateurs</h5>
                    <br />

                    <ul className="list list-hand">
                      <li>Compensation fixe. </li>
                      <li>Compensation automatique.</li>
                      <li>Compensation pour réseau non pollué. </li>
                      <li>Compensation pour réseau peu pollué. </li>
                      <li>Compensation pour réseau pollué. </li>
                    </ul>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a
                      href="/batterie_condencateur"
                      className="button button-border"
                    >
                      Détail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/**section 2 */}
        <section style={{ paddingTop: "30px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12"></div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/SEMS.png"
                    alt="Systéme de gestion de l'energie SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "620px" }}>
                    <h5 className="card-title">
                      Systéme de gestion de l'energie
                    </h5>
                    <br />

                    <ul className="list list-hand">
                      <li>SEMS: Substation Energy Management System.</li>
                      <li>IEMS: Industrial Energy Management System.</li>
                    </ul>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/eems" className="button button-border">
                      Détail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/protection tr.png"
                    alt="Systéme de gestion de l'energie SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "620px" }}>
                    <h5 className="card-title">
                      Systéme de gestion de l'energie
                    </h5>
                    <br />

                    <ul className="list list-hand">
                      <li>CP1T Coffret protection un transformateur. </li>
                      <li>CP2T Coffret protection deux transformateurs.</li>
                      <li>CP3T Coffret protection trois transformateurs.</li>
                      <li>CP4T Coffret protection quatre transformateurs.</li>
                    </ul>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a
                      href="/Coffret_Protection_Transformateur"
                      className="button button-border"
                    >
                      Détail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/SAV.png"
                    alt="Services SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "620px" }}>
                    <h5 className="card-title">Services</h5>
                    <br />
                    <p className="card-text">
                      Nos services de réparation, de maintenance et d’entretien
                      ciblent tous les types de transformateurs et des
                      équipements électriques MT/BT et qui sont:
                    </p>
                    <ul className="list list-hand">
                      <li>Traitement d’huile</li>
                      <li>Analyse d’huile</li>
                      <li>Diagnostic et inspection.</li>
                      <li>Maintenance prédictive.</li>
                      <li>Maintenance préventive.</li>
                      <li>Maintenance corrective.</li>
                    </ul>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/services" className="button button-border">
                      Détail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/**section 3 */}
        <section style={{ paddingTop: "30px", paddingBottom: "80px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12"></div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/audit energy.png"
                    alt="Sacem Energie"
                  />
                  <div className="card-body" style={{ minHeight: "270px" }}>
                    <h5 className="card-title">Sacem Energie</h5>
                    <br />
                    <p className="card-text">
                      L'objectif de l'étude énergétique est de définir un
                      programme d'action ayant pour effet de réduire les
                      consommations d'énergie en quantités physiques et en
                      coûts, avec la meilleure rentabilité économique et
                      financière des capitaux investis.
                    </p>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="#/" className="button button-border">
                      Détail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/training.png"
                    alt="Sacem Training"
                  />
                  <div className="card-body" style={{ minHeight: "270px" }}>
                    <h5 className="card-title">Sacem Training</h5>
                    <br />
                    <p className="card-text">
                      Centre de formations, perfectionnement et renforcement des
                      compétences dans les domaines électriques, de sécurité et
                      de Management.
                    </p>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/training" className="button button-border">
                      Détail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/self.png"
                    alt="Filtre active anti-harmoniques SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "270px" }}>
                    <h5 className="card-title">
                      Filtre active anti-harmoniques
                    </h5>
                    <br />
                    <p className="card-text">
                      Élimination/compensation des harmoniques pour améliorer la
                      qualité et l'efficacité de l'installation électrique.
                      <br />
                      <br />
                    </p>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/filtreactive" className="button button-border">
                      Détail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
 lists-panels */}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Produits;
