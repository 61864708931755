import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Presentation from "./Components/Presentation";
import Sav from "./Components/Sav";
import Training from "./Components/Training";
import Filtreactive from "./Components/Filtreactive";
import Produits from "./Components/Produits";
import Contact from "./Components/Contact";
import Coffret from "./Components/Coffret";
import Faq from "./Components/Faq";
import Tableauais from "./Components/Tableauais";
import Form from "./Components/Form";
import Certificats from "./Components/Certificats";
import Transformateur_DIST_24 from "./Components/Transformateur_DIST_24";
import Transformateur_DIST_36 from "./Components/Transformateur_DIST_36";
import Transformateur_Mono from "./Components/Transformateur_Mono";
import Transformateur_pui_TRIPH from "./Components/Transformateur_pui_TRIPH";
import Transformateur_Special from "./Components/Transformateur_Special";
import Transformateur from "./Components/Transformateur";
import Nouscontacter from "./Components/Nouscontacter";
import Rhcarriers from "./Components/Rhcarriers";
import Eems from "./Components/Eems";
import Batterie_Condencateur from "./Components/Batterie_Condencateur";
import Actualites from "./Components/Actualites";
import Historique from "./Components/Historique";
import Power from "./Components/Power";
import Rse from "./Components/Rse";
import Notfoundpage from "./Components/Notfoundpage";
import Fortest2 from "./Components/Fortest2";
import Fortestuse from "./Components/Fortestuse";
import Home_en from "./Components/enlgish/Home_en";
import Presentation_en from "./Components/enlgish/Presentation_en";
import Sav_en from "./Components/enlgish/Sav_en";
import Training_en from "./Components/enlgish/Training_en";
import Filtreactive_en from "./Components/enlgish/Filtreactive_en";
import Produits_en from "./Components/enlgish/Produits_en";
import Contact_en from "./Components/enlgish/Contact_en";
import Coffret_en from "./Components/enlgish/Coffret_en";
import Faq_en from "./Components/enlgish/Faq_en";
import Tableauais_en from "./Components/enlgish/Tableauais_en";
import Form_en from "./Components/enlgish/Form_en";
import Certificats_en from "./Components/enlgish/Certificats_en";
import Transformateur_DIST_24_en from "./Components/enlgish/Transformateur_DIST_24_en";
import Transformateur_DIST_36_en from "./Components/enlgish/Transformateur_DIST_36_en";
import Transformateur_Mono_en from "./Components/enlgish/Transformateur_Mono_en";
import Transformateur_pui_TRIPH_en from "./Components/enlgish/Transformateur_pui_TRIPH_en";
import Transformateur_Special_en from "./Components/enlgish/Transformateur_Special_en";
import Transformateur_en from "./Components/enlgish/Transformateur_en";
import Nouscontacter_en from "./Components/enlgish/Nouscontacter_en";
import Rhcarriers_en from "./Components/enlgish/Rhcarriers_en";
import Eems_en from "./Components/enlgish/Eems_en";
import Batterie_Condencateur_en from "./Components/enlgish/Batterie_Condencateur_en";
import Actualites_en from "./Components/enlgish/Actualites_en";
import Historique_en from "./Components/enlgish/Historique_en";
import Power_en from "./Components/enlgish/Power_en";
import Rse_en from "./Components/enlgish/Rse_en";
function App() {
  return (
    <div className="App">
      {/**loader */}

      <div>
        <div>
          {/**<Header /> */}

          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/presentation" element={<Presentation />} />
              <Route path="/histoire" element={<Historique />} />
              <Route path="/services" element={<Sav />} />
              <Route path="/formation" element={<Training />} />
              <Route path="/filtreactive" element={<Filtreactive />} />
              <Route path="/produit" element={<Produits />} />
              <Route
                path="Coffret_Protection_Transformateur"
                element={<Coffret />}
              />
              <Route path="/tableauais" element={<Tableauais />} />
              <Route
                path="/transformateur_dist_24"
                element={<Transformateur_DIST_24 />}
              />
              <Route
                path="/transformateur_dist_36"
                element={<Transformateur_DIST_36 />}
              />
              <Route
                path="/transformateur_mono"
                element={<Transformateur_Mono />}
              />
              <Route
                path="/transformateur_pui_triph"
                element={<Transformateur_pui_TRIPH />}
              />
              <Route
                path="/transformateur_special"
                element={<Transformateur_Special />}
              />
              <Route
                path="/batterie_condensateurs"
                element={<Batterie_Condencateur />}
              />
              <Route path="/transformateur" element={<Transformateur />} />
              <Route path="/faqs" element={<Faq />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/nouscontacter" element={<Nouscontacter />} />
              <Route path="/form" element={<Form />} />
              <Route path="/certificats" element={<Certificats />} />
              <Route path="/carriers" element={<Rhcarriers />} />
              <Route path="/eems" element={<Eems />} />
              <Route path="/actualites" element={<Actualites />} />
              <Route path="/tableauMT-BT" element={<Power />} />
              <Route path="/rse" element={<Rse />} />
              {/**==english routes */}
              <Route path="/en" element={<Home_en />} />
              <Route path="/en_f" element={<Home_en />} />
              <Route path="/en/presentation" element={<Presentation_en />} />
              <Route path="/en/history" element={<Historique_en />} />
              <Route path="/en/services" element={<Sav_en />} />
              <Route path="/en/training" element={<Training_en />} />
              <Route path="/en/activefilter" element={<Filtreactive_en />} />
              <Route path="/en/products" element={<Produits_en />} />
              <Route
                path="/en/Transformer_Protection_Box"
                element={<Coffret_en />}
              />
              <Route path="/en/aispanel" element={<Tableauais_en />} />
              <Route
                path="/en/distribution_transformer_24"
                element={<Transformateur_DIST_24_en />}
              />
              <Route
                path="/en/distribution_transformer_36"
                element={<Transformateur_DIST_36_en />}
              />
              <Route
                path="/en/single_phase_transformer"
                element={<Transformateur_Mono_en />}
              />
              <Route
                path="/en/Three_phase_POWER_Transformer"
                element={<Transformateur_pui_TRIPH_en />}
              />
              <Route
                path="/en/Special_Transformers"
                element={<Transformateur_Special_en />}
              />
              <Route
                path="/en/battery_of_capacitors"
                element={<Batterie_Condencateur_en />}
              />
              <Route path="/en/transformer" element={<Transformateur_en />} />
              <Route path="/en/faqs" element={<Faq_en />} />
              <Route path="/en/contact" element={<Contact_en />} />
              <Route path="/en/contactus" element={<Nouscontacter_en />} />
              <Route path="/en/form" element={<Form_en />} />
              <Route path="/en/certificats" element={<Certificats_en />} />
              <Route path="/en/carriers" element={<Rhcarriers_en />} />
              <Route path="/en/eems" element={<Eems_en />} />
              <Route path="/en/news" element={<Actualites_en />} />
              <Route path="/en/MV-BT_panel" element={<Power_en />} />
              <Route path="/en/csr" element={<Rse_en />} />
              <Route path="*" element={<Notfoundpage />} />
              <Route path="/f2" element={<Fortest2 />} />
              <Route path="/f" element={<Fortestuse />} />
              <Route path="/en" element={<Home_en />} />
            </Routes>
          </BrowserRouter>
        </div>

        <div id="back-to-top">
          <a className="top arrow" href="#top">
            <i className="fa fa-angle-up" /> <span>TOP</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
