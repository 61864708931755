import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import styled from "styled-components";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Transformateur_Mono_en = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/transformateur/Triphase/slider-triphase-36-24.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Single-phase transformers</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Our ranges of single-phase transformers
                  </h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            {/*=====================================*/}
            {/**<section className="page-section-ptb"> */}
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 mb-30 ">
                    {/** */}
                    <div
                      className="rotateInDownLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInDownLeft",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "370px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-settings theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">GENERAL CHARACTERISTICS</h5>
                          <br />
                          <p className="mb-0">
                            Three-phase transformer immersed in oil:
                          </p>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>IEC 60076 standard.</li>
                            <li>Fully filled, airtight or breathable.</li>
                            <li>Natural cooling, ONAN type.</li>
                            <li>Indoor/outdoor installation.</li>

                            <li>Colour: RAL7035 (other colours on request).</li>

                            <li>Corrosion protection: C5H.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-30 ">
                    {/** */}
                    <div
                      className="rotateInDownRight wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInDownRight",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "370px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-bolt theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">SERVICE PRESSURES</h5>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Class: 24 & 36 kV.</li>
                            <li>HV: 17.32 kV.</li>
                            <li>LV: 0.242 kV.</li>
                            <li>Coupling: Single-phase.</li>
                            <li>Frequency: 50HZ.</li>
                            <li>Conductor: Copper (Aluminium optional).</li>
                            <li>
                              Liquid dielectric: Mineral oil according to IEC
                              60296.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  {/**
                  <div className="col-lg-6 col-sm-6 xs-mb-30">
                    
                    <div
                      className="rotateInUpLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInUpLeft",
                      }}
                    >
                      <div className="media border p-4">
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-medall theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">
                            Normes et spécifications
                          </h5>
                          <br />
                          <p className="mb-0">Norme CEI 60076</p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                     
                  </div>
                  */}
                  <div className="col-lg-6 col-sm-6">
                    {/** */}
                    <div
                      className="rotateInUpLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInUpLeft",
                      }}
                    >
                      <div className="media border p-4">
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-package theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">BASIC EQUIPMENT</h5>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Lifting rings</li>
                            <li>MT crossings in porcelain</li>
                            <li>Earthing sockets</li>
                            <li>Hanging device</li>
                            <li>BT crossings in porcelain</li>
                            <li>Off-load dimmer switch</li>
                            <li>Identification plate</li>
                            <li>Drain valve</li>
                            <li>Safety valve</li>
                            <li>Finger of glove</li>
                          </ul>

                          <p className="mb-0" style={{ fontWeight: "bold" }}>
                            Note: the above options are not exhaustive
                            exhaustive.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                </div>
              </div>
              <div>
                {/*=================================
 Tableau */}
                <section id="tab36" className="page-section-ptb data-table">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <h3 style={{ color: "#00356a" }}>
                          DIMENSIONS AND WEIGHT
                        </h3>
                        <StyledHeading className="h6-line"></StyledHeading>
                        <br />
                        <div className="table-responsive">
                          <table className="table table-1 table-bordered table-striped">
                            <tbody>
                              <tr></tr>
                            </tbody>
                            <thead>
                              <tr>
                                <td>Power (kVA)</td>
                                <td>25</td>
                                <td>50</td>
                                <td>75</td>
                                <td>100</td>
                                <td>150</td>
                                <td>210</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>L</td>
                                <td>550</td>
                                <td>850</td>
                                <td>920</td>
                                <td>950</td>
                                <td>1100</td>
                                <td>1230</td>
                              </tr>
                              <tr>
                                <td>I</td>
                                <td>580</td>
                                <td>620</td>
                                <td>660</td>
                                <td>680</td>
                                <td>790</td>
                                <td>830</td>
                              </tr>
                              <tr>
                                <td>H</td>
                                <td>1200</td>
                                <td>1200</td>
                                <td>1350</td>
                                <td>1370</td>
                                <td>1480</td>
                                <td>1500</td>
                              </tr>
                              <tr>
                                <td>Weight Oil (Kg)</td>
                                <td>60</td>
                                <td>75</td>
                                <td>110</td>
                                <td>130</td>
                                <td>170</td>
                                <td>250</td>
                              </tr>
                              <tr>
                                <td>Total weight (kg)</td>
                                <td>250</td>
                                <td>320</td>
                                <td>480</td>
                                <td>600</td>
                                <td>700</td>
                                <td>1040</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="entry-button" style={{ marginLeft: 20 }}>
                    <a className="button button-border" href="/en/contact">
                      Contact us
                    </a>
                  </div>
                </section>
              </div>
            </section>
            {/*=================================
 Tableau */}
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Transformateur_Mono_en;
