import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Loader from "./Loader";
import Header from "./Header";
import Form_carriers from "./Form_carriers";

const Rhcarriers = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
  page-title*/}

        {/*=================================
  page-title */}
        {/*=================================
   careers*/}
        <section
          className="careers white-bg page-section-ptb"
          style={{ paddingTop: "160px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-title text-center">
                  <h6></h6>
                  <h2 className="title-effect">
                    Trouvez le poste fait pour vous et postulez !
                  </h2>
                  <p className></p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9" style={{ maxWidth: "65%" }}>
                {/** */}
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#/">
                      Ingénieur études
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span className="mb-10">
                            <b>Localisation : </b>Tunisie{" "}
                          </span>
                          <span>
                            <b>Département : </b>Etudes{" "}
                          </span>
                          <p className="mt-20"></p>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Description de l'emploi
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Ce que nous offrons
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          {/**  <a href="#" className="button">
                            Postuler
                          </a>*/}
                          <p className="mt-20 mb-20">
                            Les candidats intéressés et éligibles sont invités à
                            envoyer leur CV accompagné de leurs informations
                            personnelles à l'adresse e-mail suivante:{" "}
                            <a href="mailto:contact@sacemgroup.com">
                              contact@sacemgroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/** */}
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#/">
                      Ingénieur Méthodes
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span className="mb-10">
                            <b>Localisation : </b>Tunisie{" "}
                          </span>
                          <span>
                            <b>Département : </b>Etudes{" "}
                          </span>
                          <p className="mt-20"></p>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Description de l'emploi
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Ce que nous offrons
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Les candidats intéressés et éligibles sont invités à
                            envoyer leur CV accompagné de leurs informations
                            personnelles à l'adresse e-mail suivante:{" "}
                            <a href="mailto:contact@sacemgroup.com">
                              contact@sacemgroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/** */}
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#/">
                      Ingénieur Qualité
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span className="mb-10">
                            <b>Localisation : </b>Tunisie{" "}
                          </span>
                          <span>
                            <b>Département : </b>Etudes{" "}
                          </span>
                          <p className="mt-20"></p>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Description de l'emploi
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Ce que nous offrons
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Les candidats intéressés et éligibles sont invités à
                            envoyer leur CV accompagné de leurs informations
                            personnelles à l'adresse e-mail suivante:{" "}
                            <a href="mailto:contact@sacemgroup.com">
                              contact@sacemgroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/** */}
              </div>
              <Form_carriers />
            </div>
          </div>
        </section>
        {/*=================================
   careers*/}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Rhcarriers;
