import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "./Loader";
import Headeren from "./enlgish/Header_en";

const Fortestuse = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  const Textstyle = {
    fontSize: "22px",
    lineHeight: "28px",
    fontWeight: "500",
    color: "#222c5a",
  };
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  const langus = document.documentElement.lang;
  const [language, setLanguage] = useState(langus);
  useEffect(() => {
    // Update the lang attribute of the <html> tag
    document.documentElement.lang = language;
  }, [language]);

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "fr" ? "en" : "fr"));
  };

  return (
    <div>
      <Headeren />
      <Loader isVisible={isVisible} />
      <div
        className="col-lg-6 col-md-6 col-sm-6"
        style={{ paddingTop: "290px" }}
      >
        <div className="blog-entry mb-50">
          <div className="entry-image clearfix">
            <div
              className="owl-carousel bottom-center-dots"
              data-nav-dots="ture"
              data-items={1}
              data-md-items={1}
              data-sm-items={1}
              data-xs-items={1}
              data-xx-items={1}
            >
              <div className="item">
                <img
                  className="img-fluid"
                  src="images/actualité/870-400/Op-blanche01-01.jpg"
                  alt
                />
              </div>
              <div className="item">
                <img
                  className="img-fluid"
                  src="images/actualité/870-400/Op-blanche02-02.jpg"
                  alt
                />
              </div>
              <div className="item">
                <img
                  className="img-fluid"
                  src="images/actualité/870-400/Op-blanche03-03.jpg"
                  alt
                />
              </div>
            </div>
          </div>
          <div className="blog-detail">
            <div className="entry-title mb-10">
              <a href="#">
                Action blanche pour lutte contre incendie, évacuation et
                secourisme.
              </a>
            </div>
            <div className="entry-meta mb-10">
              <ul>
                <li>
                  {" "}
                  <i className="fa fa-folder-open-o" />{" "}
                  <a href="#"> Actualité</a> <a href="#">Usine </a>{" "}
                </li>
                <li>
                  <a href="#">
                    <i />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-calendar-o" /> 02 Décembre 2024
                  </a>
                </li>
              </ul>
            </div>
            <div className="entry-content">
              <p>
                SACEM GROUP مجمع صـــاســام <br />
                Retour sur l'action blanche pour lutte contre incendie,
                évacuation et secourisme. Remerciements à toute l'équipe de la
                protection civile de menzel Bourguiba et aux personnels de SACEM
                GROUP مجمع صـــاســام pour le bon déroulement de l'action.
              </p>
            </div>
          </div>
          <div
            className="entry-share clearfix"
            style={{
              paddingBottom: "1.25rem",
              paddingLeft: "1.25rem",
              paddingTop: "0.5rem",
            }}
          >
            <div className="entry-button">
              <a className="button arrow" href="#">
                Read More
                <i className="fa fa-angle-right" aria-hidden="true" />
              </a>
            </div>
            <div className="social list-style-none float-right">
              <strong>Share : </strong>
              <ul>
                <li>
                  <a href="#">
                    {" "}
                    <i className="fa fa-facebook" />{" "}
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <i className="fa fa-twitter" />{" "}
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <i className="fa fa-pinterest-p" />{" "}
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <i className="fa fa-dribbble" />{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Fortestuse;
