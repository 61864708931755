import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const HeaderHome = ({ isVisible }) => {
  // const navigate = useNavigate();
  // const [language, setLanguage] = useState("fr");

  // //const langus = document.documentElement.lang;
  // const langtobe = document.documentElement.lang === "fr" ? "en" : "fr";

  // const toggleLanguage = (e) => {
  //   e.preventDefault();
  //   // setLanguage((langus) => (langus === "fr" ? "en" : "fr"));
  //   // setLanguagetobe((langus) => (langus === "fr" ? "en" : "fr"));
  //   const newLang = language === "fr" ? "en" : "fr";
  //   // Update the lang attribute directly
  //   document.documentElement.lang = newLang;
  //   navigate(langtobe === "en" ? "en" : "/");
  //   // Update the state
  //   setLanguage(newLang);
  // };
  return (
    <div style={{ display: isVisible ? "block" : "none" }}>
      {/*=================================
   header */}

      <header id="header" className="header default">
        <div className="topbar">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 xs-mb-10">
                <div className="topbar-call text-center text-md-left">
                  <ul>
                    <li>
                      <i className="fa fa-envelope-o theme-color" />{" "}
                      <a href="mailto:contact@sacemgroup.com">
                        contact@sacemgroup.com
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-phone" />{" "}
                      <a href="tel:0021671966986">
                        {" "}
                        <span>+(216) 71 966 986 </span>{" "}
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="topbar-social text-center text-md-right">
                  <ul>
                    <li>
                      <a href="/carriers" style={{ color: "white" }}>
                        Carrière
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/sacemindustries/?locale=fr_FR"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="ti-facebook" />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://fr.linkedin.com/company/sacem-group"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="ti-linkedin" />
                      </a>
                    </li>
                    {/* <li>
                      <a href="/en">
                        <img
                          src="/assets/images/language icon/en.png"
                          alt="en"
                        />
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa fa-globe" />
                      </a>
                      <div
                        className="dropdown-menu "
                        x-placement="bottom-start"
                        style={{
                          position: "absolute",
                          transform: "translate3d(0px, 37px, 0px)",
                          top: 0,
                          left: 0,
                          willChange: "transform",
                          minWidth: "4rem",
                        }}
                      >
                        <ul
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <li
                            style={{
                              marginRight: "0%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <a href="/en" style={{ marginLeft: "0%" }}>
                              <img
                                src="/assets/images/language icon/en.png"
                                alt="en"
                              />
                            </a>
                          </li>
                          <li
                            style={{
                              marginRight: "0%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <a href="/" style={{ marginLeft: "0%" }}>
                              <img
                                src="/assets/images/language icon/fr.png"
                                alt="fr"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* <li>
                      <a href={language === "fr" ? "/en/" : "/"}>
                        <img
                          src={
                            langtobe === "fr"
                              ? "/assets/images/language icon/fr.png"
                              : "/assets/images/language icon/en.png"
                          }
                          alt="francais"
                          onClick={toggleLanguage}
                        />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=================================
   mega menu */}
        <div className="menu">
          <centre>
            {/* menu start */}
            <nav id="menu" className="mega-menu">
              {/* menu list items container */}
              <section className="menu-list-items" style={{ height: "90px" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <ul className="menu-logo">
                        <li>
                          <a href="/">
                            <img
                              id="logo_img"
                              src="/assets/images/logos/logo1.png"
                              alt="SACEM Industries"
                            />{" "}
                          </a>
                        </li>
                      </ul>
                      {/* menu logo */}

                      {/* menu links */}
                      <div className="menu-bar">
                        <ul className="menu-links">
                          {/* HOME */}
                          <li>
                            <a href="/">Accueil </a>
                          </li>
                          <li>
                            <a href="#/">
                              {" "}
                              Entreprise{" "}
                              <i className="fa fa-angle-down fa-indicator" />
                            </a>
                            {/* drop down multilevel  */}
                            <div className="drop-down-multilevel">
                              <ul>
                                <li>
                                  <a href="/presentation">
                                    <i className="fa fa-television" />
                                    Présentation{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="/actualites">
                                    <i className="fa fa-newspaper-o" />{" "}
                                    Actualités{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="/histoire">
                                    <i className="fa fa-history" /> Histoire{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="/rse">
                                    <i className="fa fa-handshake-o" /> R S E{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="/certificats">
                                    <i className="fa fa-certificate" />{" "}
                                    Certification{" "}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li>
                            <a href="/produit">
                              Produits{" "}
                              <i className="fa fa-angle-down fa-indicator" />
                            </a>
                            <ul className="drop-down-multilevel left-side">
                              <li>
                                <a href="/transformateur">
                                  Transformateurs
                                  <i className="ti-plus fa-indicator" />
                                </a>
                                {/* drop down second level */}
                                <ul className="drop-down-multilevel left-side">
                                  <li>
                                    <a href="#/">
                                      <i className="ti-plus fa-indicator" />
                                      Transformateur de distribution Triphasé
                                    </a>
                                    {/* drop down third level */}
                                    <ul className="drop-down-multilevel left-side">
                                      <li>
                                        <a href="/transformateur_dist_24">
                                          Transformateur de distribution
                                          Triphasé classe 24KV
                                        </a>
                                      </li>
                                      <li>
                                        <a href="/transformateur_dist_36">
                                          Transformateur de distribution
                                          Triphasé classe 36KV
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a href="/transformateur_pui_triph">
                                      Transformateur de PUISSANCE Triphasé
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="/transformateur_mono">
                                      Transformateurs Monophasés
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="/transformateur_special">
                                      Transformateurs Spéciaux
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="/tableauMT-BT">
                                  Tableau MT/BT
                                  <i className="ti-plus fa-indicator" />
                                </a>
                                {/* drop down second level */}
                                <ul className="drop-down-multilevel right-side">
                                  <li>
                                    <a href="/tableauais">Tableau-AIS 36KV</a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="Coffret_Protection_Transformateur">
                                      Coffret Protection Transformateur
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="/eems">
                                      Système de Gestion de l'Energie
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="/filtreactive">
                                      Filtre active anti-harmoniques
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="/batterie_condensateurs">
                                      Batterie de condensateurs
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <a href="#/">
                              SERVICES{" "}
                              <i className="fa fa-angle-down fa-indicator" />{" "}
                            </a>
                            <ul className="drop-down-multilevel left-side">
                              <li>
                                {" "}
                                <a href="/services">SAV & Réparation</a>{" "}
                              </li>
                              <li>
                                <a href="/formation">Formation</a>
                              </li>
                            </ul>
                            {/* drop down full width */}
                          </li>

                          <li>
                            <a href="/nouscontacter">NOUS CONTACTER</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </nav>
            {/* menu end */}
          </centre>
        </div>
      </header>
    </div>
  );
};

export default HeaderHome;
