import React from "react";

const NosAdresses_en = () => {
  const callcontact = {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "bold",
    color: "#029acb",
  };
  return (
    <div>
      <section>
        <div className="container">
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="col-lg-6 col-md-6"
              style={{
                // border: "1px solid rgba(0, 0, 0, 0.125)",
                minWidth: "50%",
              }}
            >
              <div className="portfolio-item">
                <img
                  className="img-fluid"
                  src="/assets/images/Usine/Usine-min.JPG"
                  alt="Usine SACEM INDUSTRIES"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  //justifyContent: "space-between",
                }}
              >
                <h2 className="text-back pt-10" style={{ textAlign: "start" }}>
                  Manzel Bourguiba factory
                </h2>
                <br />
                <a
                  href="https://maps.app.goo.gl/x2bS5Hh9R291EHDw5"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="button button-border"
                  style={{ alignSelf: "flex-start", marginTop: "auto" }}
                >
                  Direction
                </a>
              </div>
            </div>
            {/** */}
            <div className="col-lg-4 col-md-4 xs-mb-30 ">
              <ul className="price active">
                <li
                  style={{
                    padding: "10px 0px",
                    borderBottom: "0px solid #fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <h5
                      className="text-back pt-10"
                      style={{ textAlign: "start" }}
                    >
                      Head Office
                    </h5>
                    <a
                      href="https://maps.app.goo.gl/SfgZrSkTMoJNGWRd9"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="button button-border"
                      style={{ alignSelf: "flex-end", marginTop: "auto" }}
                    >
                      Direction
                    </a>
                  </div>
                </li>
                <li
                  style={{
                    padding: "10px 0px",
                    borderBottom: "0px solid #fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <h5
                      className="text-back pt-10"
                      style={{ textAlign: "start" }}
                    >
                      Commercial Head Office
                    </h5>
                    <a
                      href="https://maps.app.goo.gl/Q7h1AZ7LE3RLPJuT6"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="button button-border"
                      style={{ alignSelf: "flex-end", marginTop: "auto" }}
                    >
                      Direction
                    </a>
                  </div>
                </li>
                <li
                  style={{
                    padding: "10px 0px",
                    borderBottom: "0px solid #fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <h5
                      className="text-back pt-10"
                      style={{ textAlign: "start" }}
                    >
                      Purchase Head office
                    </h5>
                    <a
                      href="https://maps.app.goo.gl/W1XqRK8VSG3ZhNzY6"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="button button-border"
                      style={{ alignSelf: "flex-end", marginTop: "auto" }}
                    >
                      Direction
                    </a>
                  </div>
                </li>
                <li
                  style={{
                    padding: "10px 0px",
                    borderBottom: "0px solid #fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <h5
                      className="text-back pt-10"
                      style={{ textAlign: "start" }}
                    >
                      Accounting Head Office
                    </h5>
                    <a
                      href="https://maps.app.goo.gl/W1XqRK8VSG3ZhNzY6"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="button button-border"
                      style={{ alignSelf: "flex-end", marginTop: "auto" }}
                    >
                      Direction
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            {/** */}
          </div>
          {/** */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40px",
            }}
          >
            {/** */}
            <div
              className="row mt-60"
              style={{
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  paddingRight: "0.5rem",
                }}
              >
                <span className="call fa fa-phone" style={callcontact}>
                  {" "}
                  <a href="tel:21671966986">+(216) 71 966 986</a>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  paddingLeft: "0.5rem",
                }}
              >
                {" "}
                <span className="email">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "5%" }}>
                      <i
                        className="fa fa-envelope-o"
                        style={{ color: "#029acb " }}
                      />
                    </div>
                    <div style={{ paddingLeft: "0.5rem" }}>
                      <a href="mailto:contact@sacemgroup.com">
                        contact@sacemgroup.com
                      </a>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            {/** */}
            <div
              className="row "
              style={{
                marginTop: "10px",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  paddingRight: "0.5rem",
                }}
              >
                <br />
                <strong style={{ color: "#222c5a" }}>Social Networks : </strong>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",

                  paddingLeft: "0.5rem",
                }}
              >
                <a
                  href="https://www.facebook.com/sacemindustries/?locale=fr_FR"
                  style={{
                    marginRight: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <i className="fa fa-facebook" />{" "}
                </a>
                <a
                  href="https://www.instagram.com/sacem_group/?igsh=MWRhaDBraGczdmwwbg%3D%3D"
                  style={{
                    marginRight: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <i className="fa fa-instagram" />{" "}
                </a>
                <a
                  href="https://www.linkedin.com/showcase/sacem-industries/posts/?feedView=all"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {" "}
                  <i className="fa fa-linkedin" />{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NosAdresses_en;
