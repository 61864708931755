import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "../Loader";
import Banner from "../Banner";
import HeaderHome_en from "./HeaderHome_en";
import Footer_en from "./Footer_en";
import Banner_en from "./Banneren";

const Home_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 600);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  //

  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  return (
    <div>
      <Loader isVisible={isVisible} />
      <HeaderHome_en isVisible={isVisible} />
      <div style={{ display: isVisible ? "block" : "none" }}>
        <Banner_en />
        {/** <section
        className="slider-parallax typer-banner business bg-overlay-black-50 parallax"
        data-jarallax='{"speed": 0.6}'
        style={{ backgroundImage: "url(/assets/images/bg/18.jpg)" }}
      >
        <div className="slider-content-middle">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="slider-content text-center">
                  <h1 className="text-white">
                    SACEM, Make &amp;{" "}
                    <span
                      className="typer"
                      data-delay={150}
                      data-words="Move,Launch,Success"
                      data-colors="#22bef2,#22bef2,#22bef2"
                    />{" "}
                    <span
                      className="cursor"
                      data-cursordisplay="|"
                      data-owner="some-id"
                    />{" "}
                  </h1>
                  <p className="text-white mt-40">
                    "Innovative Technologies to Bring Electricity."
                  </p>
                  <a className="buttonacceuil move mt-20" href="/histoire">
                    {" "}
                    Since 1966{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>*/}

        {/*=================================banner */}
        {/*=================================about */}
        <section className="page-section-ptb">
          <div className="container">
            <div className="row">
              <div className="col-lg-6" style={{ minWidth: "56%" }}>
                <div className="section-title">
                  {/**<h2>SACEM INDUSTRIES</h2>
                  <h2 className="title-effect">
                    Technologies innovantes pour apporter l'électricité
                  </h2> */}

                  <h1 className="title-effect">SACEM INDUSTRIES</h1>
                  <StyledHeading className="h6-line"></StyledHeading>
                  <br />
                  <h5
                    style={{
                      color: "#002f6c",
                      letterSpacing: 0,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Innovative Technologies to Bring Electricity
                  </h5>
                  <br />

                  <p style={{ textAlign: "justify" }}>
                    Since 1966 SACEM Industries has been the leading
                    manufacturer and commercialising of single-phase,
                    three-phase and special electrical distribution
                    transformers.
                  </p>
                </div>

                <div
                  className="row  text-center"
                  style={{ paddingLeft: "15px" }}
                >
                  <div className="mt-30">
                    <a className="button button-border" href="/presentation">
                      About SACEM
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 sm-mt-30" style={{ maxWidth: "44%" }}>
                <img
                  src="/assets/images/Acceuil/icon SI.png"
                  alt="SACEM INDUSTRIES"
                />
                {/**
<div
                className="owl-carousel"
                data-nav-arrow="true"
                data-items={1}
                data-md-items={1}
                data-sm-items={1}
                data-xs-items={1}
                data-xx-items={1}
              >
                <div className="item">
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Acceuil/PSLIDER4.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Acceuil/PSLIDER2.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Acceuil/PSLIDER3.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
             */}
              </div>
            </div>
          </div>
        </section>
        {/*=================================Nos actualité -*/}

        {/*=================================Nos actualité -*/}
        <section>
          <div className="container">
            <h2 className="title-effect">Latest news</h2>
            <StyledHeading></StyledHeading>
            <br />
            <div
              className="row"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="about-mission-box white-bg h-100">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/actualité/home/ACT1.jpg"
                    alt="site SACEM POWER SYSTEMS"
                  />
                  <div className="content">
                    <p className="mb-0">
                      The new SACEM POWER SYSTEMS production site.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="about-mission-box white-bg h-100">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/actualité/home/ACT2.jpg"
                    alt="Renovation_bibliotheque"
                  />
                  <div className="content">
                    <p className="mb-0">
                      Renovation of the Menzel Bourguiba national library.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="about-mission-box white-bg h-100">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/actualité/home/ACT3.jpg"
                    alt="Transformateur_20_MVA"
                  />
                  <div className="content">
                    <p className="mb-0">Export 20 MVA power transformer.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="about-mission-box white-bg h-100">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/actualité/home/ACT4.jpg"
                    alt="municipalité de TINJAH"
                  />
                  <div className="content">
                    <p className="mb-0">
                      Agreement with the municipality of TINJAH on the occasion
                      of Environmental Education for a Sustainable Mediterranean
                      Day.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row justify-content-center text-center">
            <div className="mt-30">
              <a className="button button-border" href="/actualites">
                all our news
              </a>
            </div>
          </div>
        </section>
        {/*=================================Nos Expertise*/}
        <section className="blog white-bg page-section-ptb">
          <div className="container">
            <h2>Our expertises</h2>
            <StyledHeading></StyledHeading>
            <div className="col-lg-12 col-md-12 text-center">
              {/** <div className="divider double" /> */}
              <br />
              <br />
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="blog-entry mb-50">
                  <div className="entry-image clearfix">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Puissance/20MV.png"
                          alt="SACEM Transformateur 20MV"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Triphase/triphasé.png"
                          alt="SACEM Transformateur triphasé"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Specials/special.png"
                          alt="SACEM Transformateur special"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Mono/MONO.png"
                          alt="SACEM Transformateur mono"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="blog-detail" style={{ height: "410px" }}>
                    <div className="entry-title mb-10">
                      <h3>Transformer</h3>
                    </div>
                    <div className="entry-content">
                      <p style={{ textAlign: "justify" }}>
                        At SACEM INDUSTRIES, we are committed to providing
                        reliable and innovative power transformation solutions
                        tailored to the specific needs of our customers. With
                        leading-edge technical expertise and unrivalled
                        know-how, we design and manufacture transformers to the
                        highest standards of quality and performance. Our team,
                        driven by the values of professionalism, commitment and
                        teamwork, ensures that we are with you every step of the
                        way, from design to maintenance, to guarantee the
                        sustainability of your installations. Together, let's
                        build a safer, more sustainable energy future.
                      </p>
                    </div>
                    <div className="entry-share clearfix">
                      <div className="entry-button">
                        <a className="button arrow" href="/transformateur">
                          Read more
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ================================================ */}
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="blog-entry mb-50">
                  <div className="entry-image clearfix">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/HTA.png"
                          alt="Tableau-AIS"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/ENERGY MANAGEMENT.png"
                          alt="Système de Gestion de l'Energie"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/coffret protection.png"
                          alt="Coffret Protection Transformateur"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/powerbc.png"
                          alt="Batterie de Condensateurs"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="blog-detail" style={{ height: "410px" }}>
                    <div className="entry-title mb-10">
                      <h3>Power Systems</h3>
                    </div>
                    <div className="entry-content">
                      <p style={{ textAlign: "justify" }}>
                        At SACEM POWER, we offer low and medium voltage
                        products. Our offer covers electrical protection,
                        distribution and optimisation products, such as 36kV
                        cubicles with air busbars, capacitor banks, transformer
                        protection enclosures, metering enclosures, as well as
                        TUR and TIPI enclosures, designed to meet the highest
                        performance and safety requirements. Our commitment is
                        based on quality, innovation and supporting our
                        customers every step of the way to ensure the
                        reliability and durability of their energy
                        installations.
                      </p>
                    </div>
                    <div className="entry-share clearfix">
                      <div className="entry-button">
                        <a className="button arrow" href="/">
                          Read more
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="blog-entry mb-50">
                  <div className="entry-image clearfix">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/services/SV1.png"
                          alt="Service SACEM"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/services/SV2.png"
                          alt="Service SACEM"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/services/SV1.png"
                          alt="Service SACEM"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="blog-detail" style={{ height: "410px" }}>
                    <div className="entry-title mb-10">
                      <h3>SAV & Repairs</h3>
                    </div>
                    <div className="entry-content">
                      <p style={{ textAlign: "justify" }}>
                        At SACEM INDUSTRIES, we put our expertise at the service
                        of transformer maintenance and repair, guaranteeing
                        optimum performance and extended life for your
                        equipment. With an experienced team and rapid response
                        times, we provide a high-quality service that complies
                        with safety and reliability standards. Guided by the
                        values of professionalism, commitment and teamwork, we
                        are at your side to minimise downtime and maximise the
                        efficiency of your energy installations.
                        <br />
                        <br />
                      </p>
                    </div>
                    <div className="entry-share clearfix">
                      <div className="entry-button">
                        <a className="button arrow" href="/services">
                          Read more
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="blog-entry mb-50">
                  <div className="entry-image clearfix">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/training/FORMATION1.png"
                          alt="SACEM Training"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/training/FORMATION2.png"
                          alt="SACEM Training"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/training/FORMATION1.png"
                          alt="SACEM Training"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="blog-detail" style={{ height: "410px" }}>
                    <div className="entry-title mb-10">
                      <h3>Formation</h3>
                    </div>
                    <div className="entry-content">
                      <p style={{ textAlign: "justify" }}>
                        At SACEM Training, our mission is to train competent
                        professionals who are ready to meet the challenges of
                        the industry. Through tailor-made training programmes,
                        led by passionate experts, we provide our learners with
                        the technical and practical skills they need to excel in
                        their field. Our approach combines professionalism,
                        commitment and teamwork, guaranteeing an enriching
                        learning experience tailored to the needs of the market.
                        Join us to develop your talents and shape the future of
                        your career together.
                      </p>
                    </div>
                    <div className="entry-share clearfix">
                      <div className="entry-button">
                        <a className="button arrow" href="/training">
                          Read more
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*================================= Certificat*/}
            <div className="row mt-70">
              <div className="col-lg-12 col-md-12">
                <h2 className="tile-headline">Our certificates</h2>
                <StyledHeading></StyledHeading>
                {/**<div className="divider double" />*/}
                <br />
                <br />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="clients-list">
                <div
                  className="owl-carousel"
                  data-nav-dots="false"
                  data-items={3}
                  data-md-items={4}
                  data-sm-items={3}
                  data-xs-items={2}
                  data-xx-items={2}
                >
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/ISO9001.png"
                      alt="ISO9001"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/ISO14001.png"
                      alt="ISO14001"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/ISO45001.png"
                      alt="ISO45001"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*=================================clients */}
            <div className="row mt-70">
              <div className="col-lg-12 col-md-12">
                <h2>Our customers</h2>
                <StyledHeading></StyledHeading>
                {/**<div className="divider double" /> */}
                <br />
                <br />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="clients-list">
                <div
                  className="owl-carousel"
                  data-nav-dots="false"
                  data-items={5}
                  data-md-items={4}
                  data-sm-items={3}
                  data-xs-items={2}
                  data-xx-items={2}
                >
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO1.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO2.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO3.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO4.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO5.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO6.png"
                      alt=""
                    />
                  </div>

                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO9.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO10.png"
                      alt=""
                    />
                  </div>
                  {/**11-20 */}
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO11.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO12.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO13.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO14.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO15.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO16.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO17.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO18.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO19.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO20.png"
                      alt=""
                    />
                  </div>
                  {/**21-30 */}
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO21.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO22.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO23.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO24.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO25.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO26.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO27.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO28.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO29.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO30.png"
                      alt=""
                    />
                  </div>
                  {/** 31-40*/}
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO31.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO32.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO33.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO34.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO35.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO36.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO37.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO38.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO39.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO40.png"
                      alt=""
                    />
                  </div>
                  {/**41-50 */}
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO41.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO42.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO43.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO44.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO45.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO46.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO47.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO48.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO49.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO50.png"
                      alt=""
                    />
                  </div>
                  {/**51-55 */}
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO51.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO52.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO53.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO54.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO55.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {/** fin client corosel */}
          </div>
        </section>
      </div>
      <Footer_en />
    </div>
  );
};

export default Home_en;
