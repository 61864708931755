import React from "react";

const Cerrtifs_Kema = () => {
  const cert100KEMA = "/assets/images/Certificat/100 KEMA certificat.png";
  const cert250KEMA = "/assets/images/Certificat/250 KEMA certificat.png";
  const cert630KEMA = "/assets/images/Certificat/630 KEMA  certificat.png";
  const cert1250KEMA = "/assets/images/Certificat/1250 KEMA certificat.png";
  const cert1500KEMA = "/assets/images/Certificat/1500 KEMA  certificat.png";

  return (
    <div>
      <section className="shop grid">
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="row">
                {/** */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-kema-01-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert100KEMA}
                          alt="100 KEMA certificat"
                        />
                        <br />
                        <br />
                        <h3>100 KEMA certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>

                {/** */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-kema-02-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert250KEMA}
                          alt="250 KEMA certificat"
                        />
                        <br />
                        <br />
                        <h3>250 KEMA certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}

                {/** */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-kema-04-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert630KEMA}
                          alt="630 KEMA certificat"
                        />
                        <br />
                        <br />
                        <h3>630 KEMA certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-kema-05-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert1250KEMA}
                          alt="1250 KEMA certificat"
                        />
                        <br />
                        <br />
                        <h3>1250 KEMA certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-kema-06-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={cert1500KEMA}
                          alt="1500 KEMA certificat"
                        />
                        <br />
                        <br />
                        <h3>1500 KEMA certificat</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}

                {/** */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/** */}
      <div
        className="modal fade bd-kema-01-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert100KEMA}
                alt="100 KEMA certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      {/** */}
      <div
        className="modal fade bd-kema-02-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert250KEMA}
                alt="250 KEMA certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      {/** */}

      {/** */}
      {/** */}
      <div
        className="modal fade bd-kema-04-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert630KEMA}
                alt="630 KEMA certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      {/** */}
      <div
        className="modal fade bd-kema-05-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert1250KEMA}
                alt="1250 KEMA certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      {/** */}
      <div
        className="modal fade bd-kema-06-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={cert1500KEMA}
                alt="1500 KEMA certificat"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      {/** */}
    </div>
  );
};

export default Cerrtifs_Kema;
