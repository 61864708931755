import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Coffret_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{ backgroundImage: "url(/assets/images/slidebat.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Transformer protection enclosure</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Transformer protection enclosure</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 Premium Features */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div style={{ textAlign: "end" }}>
              {/*<a className="button" href="#">
                Nous Contacter1 <i className="fa fa-angle-right" />
              </a>*/}
            </div>
            <div
              className="row justify-content-center"
              style={{ paddingRight: "15px", paddingLeft: "15px" }}
            >
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect" style={{ color: "#00356a" }}>
                    Transformer protection enclosure
                  </h2>
                  <br />
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="who-we-are-left">
                      <div
                        className="owl-carousel"
                        data-nav-dots="true"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div className="item">
                          <img
                            className="img-fluid full-width"
                            src="/assets/images/CP1T.png"
                            alt="coffret de protection"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid full-width"
                            src="/assets/images/CP2T.png"
                            alt="coffret de protection"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid full-width"
                            src="/assets/images/cp1T open.png"
                            alt="coffret de protection"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 sm-mt-30">
                    {/** */}
                    <div
                      className="bounceIn wow"
                      style={{
                        visibility: "visible",
                        animationName: "bounceIn",
                      }}
                    >
                      <div className="section-title">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <p>
                          The transformer protection enclosure is designed to to
                          monitor and protect transformers transformers by
                          tripping the associated upstream and downstream
                          associated protection devices.
                        </p>
                        <div className="row mt-30">
                          <div className="col-md-6">
                            <ul className="list list-arrow">
                              <li>
                                {" "}
                                CP1T single transformer protection enclosure
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="list list-arrow">
                              <li>
                                {" "}
                                Two-transformer protection enclosure CP2T
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row mt-30">
                          <div className="col-md-6">
                            <ul className="list list-arrow">
                              <li>
                                {" "}
                                CP3T three-transformer protection enclosure
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="list list-arrow">
                              <li>
                                {" "}
                                Four-transformer protection enclosure CP4T
                              </li>
                            </ul>
                          </div>
                        </div>
                        <br />
                        <ul
                          className="list list-unstyled list-hand"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <li> With DMCR and DGPT2 relays</li>
                          <li>With BUCCHOLZ relay</li>
                        </ul>

                        <br />
                        <div style={{ textAlign: "center" }}>
                          <a className="button button-border" href="/contact">
                            Contact us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="feature-text left-icon mt-50 xs-mt-20">
                        <div className="feature-icon">
                          <span
                            className="ti-desktop theme-color"
                            aria-hidden="true"
                          />
                        </div>

                        <div className="feature-info">
                          <h5 className="text-back">
                            Reliable transformer protection
                          </h5>
                          <div
                            className="fadeInLeft wow"
                            style={{
                              visibility: "visible",
                              animationName: "fadeInLeft",
                            }}
                          >
                            <p>
                              CPT enclosures are designed to protect
                              transformers transformers against various hazards
                              such as overloads overloads and overheating.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="feature-text left-icon mt-50 xs-mt-20">
                        <div className="feature-icon">
                          <span
                            className="ti-server theme-color"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="feature-info">
                          <h5 className="text-back">
                            Reliability and Durability of Installations
                          </h5>
                          <div
                            className="fadeInUp wow"
                            style={{
                              visibility: "visible",
                              animationName: "fadeInUp",
                            }}
                          >
                            <p>
                              CPT enclosures reduce maintenance costs and
                              maintenance costs and extend the life of
                              transformers, resulting in greater for customers.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="feature-text left-icon mt-50 xs-mt-20">
                        <div className="feature-icon">
                          <span
                            className="ti-light-bulb theme-color"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="feature-info">
                          <h5 className="text-back">
                            Energy Efficiency and Performance Performance
                          </h5>
                          <div
                            className="fadeInRight wow"
                            style={{
                              visibility: "visible",
                              animationName: "fadeInRight",
                            }}
                          >
                            <p>
                              Well-designed enclosures also ensure better
                              thermal management thermal management of
                              transformers, by facilitating heat dissipation and
                              minimising minimising energy losses. Poor quality
                              enclosures can lead to overheating of the
                              components components, which affects the
                              transformer's efficiency of the transformer and
                              increases energy costs.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="page-section-ptb"></section>
              {/* ============================================ */}
              {/*=================================Premium Features */}
              {/*=================================Work Process  */}
              <section>
                <div
                  className="row"
                  style={{ paddingRight: "15px", paddingLeft: "15px" }}
                >
                  <div className="col-lg-3 col-sm-6">
                    <div className="product xs-mb-30">
                      <div className="product-image">
                        <img
                          className="img-fluid mx-auto"
                          src="/assets/images/CP1T.png"
                          alt="coffret de protection"
                        />
                        <div className="product-overlay">
                          <h5>Transformer Protection Box</h5>
                          <br />
                          <br />
                          <a
                            className="button button-border"
                            href="/assets/brochures/notice CP1T relais BUCHHOLZ.PDF"
                          >
                            Catalogue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="product">
                      <div className="product-image">
                        <img
                          className="img-fluid mx-auto"
                          src="/assets/images/CP2T.png"
                          alt="coffret de protection"
                        />
                        <div className="product-overlay">
                          <h5>Two Transformer Protection Box</h5>
                          <br />
                          <br />
                          <a
                            className="button button-border"
                            href="/assets/brochures/notice CP1T AVEC RELAIS DE DMCR OU DGPT2 specif .pdf"
                          >
                            Catalogue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 sm-mt-40">
                    <div className="offer-banner-1 text-center">
                      <div>
                        <div className="line-effect">
                          <img
                            className="img-fluid"
                            src="/assets/images/cp1T open.png"
                            alt="coffret de protection"
                          />
                          <div className="overlay" />
                        </div>
                      </div>
                      <div className="banner-content"></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Coffret_en;
