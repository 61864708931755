import React from "react";

const Certifs_Iso = () => {
  const certiso9001 = "/assets/images/Certificat/CERTIFICATISO9001.png";
  const certiso14001 = "/assets/images/Certificat/CERTIFICAT ISO 14001.png";
  const certiso45001 = "/assets/images/Certificat/CERTIFICATISO45001.png";

  return (
    <div>
      <section className="shop grid ">
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="row">
                {/** */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-iso-01-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={certiso9001}
                          alt="CERTIFICAT ISO 9001"
                        />
                        <br />
                        <br />
                        <h3>CERTIFICAT ISO 9001</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-iso-02-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={certiso14001}
                          alt="CERTIFICAT ISO 14001"
                        />
                        <br />
                        <br />
                        <h3>CERTIFICAT ISO 14001</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="product mb-40">
                    <div className="product-image">
                      <a
                        className="button button-border mt-20"
                        data-toggle="modal"
                        data-target=".bd-iso-03-modal-lg"
                        href="#/"
                      >
                        <img
                          style={{ height: "350px", width: "250px" }}
                          className="img-fluid mx-auto"
                          src={certiso45001}
                          alt="CERTIFICAT ISO 45001"
                        />
                        <br />
                        <br />
                        <h3>CERTIFICAT ISO 45001</h3>
                      </a>
                    </div>
                  </div>
                </div>
                {/** */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/** */}
      <div
        className="modal fade bd-iso-01-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={certiso9001}
                alt="1500 kVA VEIKI VNL"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      <div
        className="modal fade bd-iso-02-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={certiso14001}
                alt="ISO 14001"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
      <div
        className="modal fade bd-iso-03-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body text-justify">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                X
              </button>
              <br />
              <img
                className="img-fluid mx-auto"
                src={certiso45001}
                alt="ISO 45001"
              />
            </div>
          </div>
        </div>
      </div>
      {/** */}
    </div>
  );
};

export default Certifs_Iso;
