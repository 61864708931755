import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import Header from "./Header";
import Loader from "./Loader";
import Footer from "./Footer";

const Presentation = () => {
  // const styles = {
  //   h6: {
  //     fontSize: "16px",
  //     fontStyle: "normal",
  //     fontWeight: 600,
  //     lineHeight: "18px",
  //   },
  // };
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          /*style={{ backgroundImage: "url(/assets/images/bg/02.jpg)" }}*/
          style={{
            backgroundImage:
              "url(/assets/images/Entreprise/entreprise-prensentation.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Présentation</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Présentation</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        <section className="page-section-ptb" style={{ paddingBottom: "0px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h1 className="title-effect">A propos de SACEM</h1>
                  <StyledHeading className="h6-line"></StyledHeading>

                  <p>
                    <br />
                    Depuis 1966, SACEM Industries est le leader tunisien dans la
                    fabrication et la distribution de transformateurs
                    électriques monophasés, triphasés et spéciaux.
                  </p>
                </div>
                <p>
                  Située à Menzel Bourguiba-Tinja, notre usine s'étend sur un
                  site de 6 hectares, avec 18 000 m² de surface couverte, et
                  rassemble une équipe de 450 collaborateurs qualifiés et
                  dévoués à la satisfaction de nos clients dans plus de 30 pays.
                  Nos ingénieurs, experts en innovation produit, travaillent
                  avec passion pour offrir des solutions toujours plus
                  performantes. SACEM Industries investit continuellement dans
                  la formation de son personnel pour garantir des normes de
                  fabrication parmi les plus rigoureuses de l'industrie.
                </p>
              </div>
              <div className="col-lg-6 sm-mt-30">
                <div
                  className="owl-carousel"
                  data-nav-arrow="true"
                  data-items={1}
                  data-md-items={1}
                  data-sm-items={1}
                  data-xs-items={1}
                  data-xx-items={1}
                >
                  <div className="item">
                    <img
                      className="img-fluid full-width"
                      src="/assets/images/Entreprise/P1.png"
                      alt="Transformateur SACEM"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid full-width"
                      src="/assets/images/Entreprise/P2.png"
                      alt="TEP SACEM"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid full-width"
                      src="/assets/images/Entreprise/P3.png"
                      alt="Cellules SACEM Power"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid full-width"
                      src="/assets/images/Entreprise/P4.png"
                      alt="SACEM Industries"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid full-width"
                      src="/assets/images/Entreprise/P5.png"
                      alt=" SACEM Power"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <p style={{ paddingLeft: "15px" }}>
                Grâce aux autres entités de SACEM GROUP, nous vous proposons
                également :
                <br />
                <br />{" "}
                <span style={{ fontWeight: "bold" }}>SACEM Service :</span> Un
                service après-vente réactif, avec une équipe expérimentée pour
                l'entretien et la réparation de vos transformateurs, dans des
                conditions optimales de rapidité et de sécurité.
                <br /> <br />
                <span style={{ fontWeight: "bold" }}>
                  SACEM Training :
                </span>{" "}
                Premier centre de formation de son genre en Afrique, SACEM
                Training partage plus de 50 ans d'expertise en transformateurs
                électriques, avec des programmes conformes aux standards
                internationaux.
              </p>
              <p style={{ paddingLeft: "15px" }}>
                Nos services et notre savoir-faire font de SACEM Industries un
                partenaire de choix pour vos projets énergétiques, alliant
                excellence et durabilité.{" "}
              </p>
            </div>
          </div>
        </section>
        {/**** */}
        <section className="page-section-ptb1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="section-title"
                  style={{ marginBottom: "0px", paddingTop: "40px" }}
                >
                  <h1 className="title-effect">SACEM en chiffres</h1>
                  <StyledHeading className="h6-line"></StyledHeading>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/** */}

        <section className=" text-center">
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                className="col-lg-3 col-sm-6 xs-mb-30"
                // style={{ maxWidth: "22.5%" }}
              >
                <div className="counter theme-color text-center">
                  <label style={{ height: "60px", paddingTop: "5%" }}>
                    <h4>Transformateurs</h4>
                  </label>
                  <br />
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div>
                      <div>
                        <CountUp
                          className="timer theme-color"
                          end={10000}
                          duration={3}
                          separator=""
                        />
                      </div>

                      {/* <div style={{ paddingTop: "30px" }}>
                      <span>
                        <h6></h6>
                      </span>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/** */}
              <div
                className="col-lg-3 col-sm-6 xs-mb-30"
                // style={{ maxWidth: "22.5%" }}
              >
                <div className="counter theme-color text-center">
                  <label style={{ height: "60px" }}>
                    <h4>Capacité de Production Annuelle </h4>
                  </label>
                  <br />
                  <div>
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div>
                        <CountUp
                          className="timer theme-color"
                          end={2000}
                          duration={3}
                          separator=""
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <h6>&emsp;MVA</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** */}
              <div
                className="col-lg-3 col-sm-6 xs-mb-30"
                // style={{ maxWidth: "22.5%" }}
              >
                <div className="counter theme-color text-center">
                  <label style={{ height: "60px" }}>
                    <h4>Superficie de production </h4>
                  </label>

                  <div>
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div>
                        <CountUp
                          className="timer theme-color"
                          end={60000}
                          duration={3}
                          separator=""
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <h6>&emsp;m2</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** */}
              {/* <div
                className="col-lg-3 col-sm-6 xs-mb-30"
                style={{ maxWidth: "17.5%" }}
              >
                <div className="counter theme-color text-center">
                  <div className="divider" />
                  <label  style={{ height: "60px" }}>
                    <h4>Export vers </h4>
                  </label>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyItems: "center",
                    }}
                  >
                    <div style={{ width: "70%" }}>
                      <CountUp
                        className="timer theme-color"
                        end={30}
                        duration={3}
                        separator=""
                      />
                    </div>

                    <div style={{ paddingTop: "30px" }}>
                      <span>
                        <h6>Pays</h6>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
              {/** */}
              {/* <div className="col-lg-3 col-sm-6" style={{ maxWidth: "15%" }}>
                <div className="counter theme-color text-center">
                  <div className="divider" />
                  <label  style={{ height: "60px" }}>
                    <h4>Employers</h4>
                  </label>
                  <br />
                  <CountUp
                    className="timer theme-color"
                    end={450}
                    duration={3}
                    separator=""
                  />
                </div>
              </div> */}
            </div>

            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "30px",
              }}
            >
              {/** */}
              <div className="col-lg-3 col-sm-6 xs-mb-30">
                <div className="counter theme-color text-center">
                  <label>
                    <h4>Employers</h4>
                  </label>
                  <br />
                  <CountUp
                    className="timer theme-color"
                    end={450}
                    duration={3}
                    separator=""
                  />
                </div>
              </div>
              {/** */}
              <div className="col-lg-3 col-sm-6 xs-mb-30 ">
                <div className="counter theme-color text-center">
                  <label>
                    <h4>Export vers </h4>
                  </label>
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div>
                      <div style={{ width: "50%" }}>
                        <CountUp
                          className="timer theme-color"
                          end={30}
                          duration={3}
                          separator=""
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyItems: "center",
                      }}
                    >
                      <div style={{ paddingTop: "30px" }}>
                        <span>
                          <h6>&emsp;Pays</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/**** */}
        <section className="page-section-ptb1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="section-title"
                  style={{ marginBottom: "0px", paddingTop: "40px" }}
                >
                  <h1 className="title-effect">Valeur de SACEM</h1>
                  <StyledHeading className="h6-line"></StyledHeading>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/** */}
        <section
          className="service white-bg "
          style={{ paddingBottom: "80px" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h1>
                    Nous bâtissons par nos valeurs d'aujourd'hui, notre
                    excellence de demain : TEP
                  </h1>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-4">
                <div className="mb-30">
                  <h1>
                    01<span className="theme-color">.</span>
                  </h1>
                  <h3>Travail en équipe</h3>
                  <p style={{ textAlign: "justify" }}>
                    Le travail en équipe est au cœur de notre succès. Nous
                    croyons que les meilleurs résultats proviennent de la
                    collaboration entre nos différents départements, nos
                    partenaires et nos clients. Chaque membre de notre équipe
                    apporte ses compétences spécifiques et son expertise,
                    contribuant ainsi à la réalisation d'objectifs communs.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="mb-30">
                  <h1>
                    02<span className="theme-color">.</span>
                  </h1>
                  <h3>Engagement</h3>
                  <p style={{ textAlign: "justify" }}>
                    L'Engagement est au cœur de toutes nos actions. Nous nous
                    investissons pleinement pour répondre aux attentes de nos
                    clients et partenaires, en leur offrant des solutions sur
                    mesure et une fiabilité sans compromis
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="text-left">
                  <h1>
                    03<span className="theme-color">.</span>
                  </h1>
                  <h3>Professionnalisme</h3>
                  <p style={{ textAlign: "justify" }}>
                    Nous incarnons le professionnalisme à travers chaque aspect
                    de notre activité. Cela se traduit par un engagement
                    indéfectible envers la qualité, la compétence et la
                    transparence dans toutes nos interactions avec nos clients
                    et partenaires
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Presentation;
