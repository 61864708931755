import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Produits_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{ backgroundImage: "url(/assets/images/bg/1920-1000.jpg)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM product</h1>
                  <p>We know the secret of your success</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Product</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=======================page-title */}
        {/*========= lists-panels */}
        {/**section 1 */}
        <section style={{ paddingTop: "80px" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">Our products</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h4 className="mb-30" style={{ color: "black" }}>
                  We offer a wide range of products, systems, services and
                  solutions for customers in a wide range of sectors. sectors.
                  You can explore them by sector below.
                </h4>
              </div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/transformateurs.png"
                    alt="Transformateur électrique SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "420px" }}>
                    <h5 className="card-title">Electrical transformer</h5>
                    <br />
                    <ul className="list list-hand">
                      <li>
                        Three-phase distribution transformers up to 36 kV and
                        3000 kVA class.
                      </li>
                      <li>
                        Three-phase power transformers up to class 36 kV and 40
                        MVA.
                      </li>
                      <li>
                        Single-phase distribution transformers up to 36 kV and
                        210 kVA class.
                      </li>
                      <li>Special transformers.</li>
                    </ul>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/transformateur" className="button button-border">
                      Detail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/cellules AIS.png"
                    alt="Tableau moyenne tension 36 kV SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "420px" }}>
                    <h5 className="card-title">
                      36 kV medium-voltage switchgear
                    </h5>
                    <br />

                    <ul className="list list-hand">
                      <li>Network cell (Arrival/Departure).</li>
                      <li>Transformer protection cell with fuse.</li>
                      <li>Voltage measurement cell.</li>

                      <li>Double circuit-breaker cubicle.</li>
                    </ul>
                    <p />
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/tableauais" className="button button-border">
                      Detail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/batterie.png"
                    alt="Batterie de condensateurs SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "420px" }}>
                    <h5 className="card-title">Battery of capacitors</h5>
                    <br />

                    <ul className="list list-hand">
                      <li>Fixed compensation. </li>
                      <li>Automatic compensation.</li>
                      <li>Compensation for unpolluted network. </li>
                      <li>Compensation for low-pollution networks. </li>
                      <li>Compensation for polluted networks. </li>
                    </ul>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a
                      href="/batterie_condencateur"
                      className="button button-border"
                    >
                      Detail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/**section 2 */}
        <section style={{ paddingTop: "30px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12"></div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/SEMS.png"
                    alt="Systéme de gestion de l'energie SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "620px" }}>
                    <h5 className="card-title">Energy management system</h5>
                    <br />

                    <ul className="list list-hand">
                      <li>SEMS: Substation Energy Management System.</li>
                      <li>IEMS: Industrial Energy Management System.</li>
                    </ul>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/eems" className="button button-border">
                      Detail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/protection tr.png"
                    alt="Systéme de gestion de l'energie SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "620px" }}>
                    <h5 className="card-title">Energy management system</h5>
                    <br />

                    <ul className="list list-hand">
                      <li>CP1T Single transformer protection box. </li>
                      <li>CP2T Two-transformer protection box.</li>
                      <li>CP3T Three-transformer protection box.</li>
                      <li>CP4T Four-transformer protection box.</li>
                    </ul>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a
                      href="/Coffret_Protection_Transformateur"
                      className="button button-border"
                    >
                      Detail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/SAV.png"
                    alt="Services SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "620px" }}>
                    <h5 className="card-title">Services</h5>
                    <br />
                    <p className="card-text">
                      Our repair, maintenance and servicing services cover all
                      types of transformers and MV/LV electrical MV/LV
                      electrical equipment:
                    </p>
                    <ul className="list list-hand">
                      <li>Oil treatment</li>
                      <li>Oil analysis</li>
                      <li>Diagnostics and inspection.</li>
                      <li>Predictive maintenance.</li>
                      <li>Preventive maintenance.</li>
                      <li>Corrective maintenance.</li>
                    </ul>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/services" className="button button-border">
                      Detail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/**section 3 */}
        <section style={{ paddingTop: "30px", paddingBottom: "80px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12"></div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/audit energy.png"
                    alt="Sacem Energie"
                  />
                  <div className="card-body" style={{ minHeight: "270px" }}>
                    <h5 className="card-title">Sacem Energy</h5>
                    <br />
                    <p className="card-text">
                      The aim of the energy study is to define a programme of
                      programme of action to reduce energy consumption in both
                      energy consumption in terms of both physical quantities
                      and with the best possible economic and financial return
                      financial return on capital invested.
                    </p>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="#/" className="button button-border">
                      Detail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 sm-mb-30">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/training.png"
                    alt="Sacem Training"
                  />
                  <div className="card-body" style={{ minHeight: "270px" }}>
                    <h5 className="card-title">Sacem Training</h5>
                    <br />
                    <p className="card-text">
                      Centre for training, further training and skills
                      enhancement electrical, safety and management management.
                    </p>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/training" className="button button-border">
                      Detail
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card">
                  <img
                    className="card-img-top"
                    src="/assets/images/Products/self.png"
                    alt="Filtre active anti-harmoniques SACEM"
                  />
                  <div className="card-body" style={{ minHeight: "270px" }}>
                    <h5 className="card-title">Active anti-harmonic filter</h5>
                    <br />
                    <p className="card-text">
                      Elimination/compensation of harmonics to improve the
                      quality quality and efficiency of electrical
                      installations.
                      <br />
                      <br />
                    </p>
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingLeft: "1.25rem",
                      paddingTop: "0.5rem",
                    }}
                  >
                    <a href="/filtreactive" className="button button-border">
                      Detail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*========lists-panels=== */}
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Produits_en;
