import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Training_en = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{
            backgroundImage: "url(/assets/images/training/Train-01.jpg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM TRAINING</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> HOME
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>

                  <li>
                    <span>SACEM TRAINING</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 Premium Features */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            {/**  <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">SACEM TRAINING</h2>
                </div>
              </div>
            </div> */}

            {/* ============================================ */}
            <div className="service-3">
              <section className="service white-bg">
                <div className="container">
                  <div className="service-3">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div
                          className="section-title slideInLeft wow"
                          style={{
                            visibility: "visible",
                            animationName: "slideInLeft",
                          }}
                        >
                          <div
                            className="service-blog text-left"
                            style={{ marginBottom: "0px" }}
                          >
                            <div className="section-title">
                              <h2 className="title-effect">
                              About SACEM TRAINING
                              </h2>
                              <StyledHeading className="h6-line"></StyledHeading>
                            </div>
                            <p style={{ textAlign: "justify" }}>
                            SACEM TRAINING is the first center of its kind in the MENA region, specializing in electricity and energy. 
                            Our training sessions are offered as follows.
                            </p>
                            <ul
                              className="list list-unstyled list-hand"
                              style={{
                                borderRight: "0px solid white",
                                textAlign: "justify",
                              }}
                            >
                              <li>
                                <h4
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                  }}
                                >
                                  INTER-COMPANIES
                                </h4>
                              </li>

                              <p style={{ paddingLeft: "25px" }}>
                              Several companies take part in the same training session on a predefined theme.
                              SACEM TRAINING welcomes participants to its training rooms.
                              </p>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                        <div style={{ paddingTop: "3rem" }}>
                          <img
                            className="img-fluid full-width"
                            src="/assets/images/training/Train-01.jpg"
                            alt="filtre-active-pqsine"
                          />
                        </div>
                        <div
                          className="row"
                          style={{
                            paddingBottom: "1.25rem",
                            paddingTop: "1.5rem",
                            justifyContent: "flex-end",
                            paddingRight: "15px",
                          }}
                        >
                          <a href="/Contact" className="button button-border">
                          Contact us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/** */}
                  <div
                    className="section-title row slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <ul
                      className="service-blog list list-unstyled list-hand"
                      style={{ padding: "0 15px", textAlign: "justify" }}
                    >
                      <li>
                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          INTRA-COMPANY
                        </h4>
                      </li>
                      <p style={{ paddingLeft: "25px" }}>
                      Participants in these sessions belong to the same company, which means that the courses can be personalized. 
                      Depending on the customer's choice, training takes place either on the customer's own premises or on SACEM TRAINING's premises.
                      </p>

                      <li>
                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          CUSTOMIZED ASSISTANCE
                        </h4>
                      </li>
                      <p style={{ paddingLeft: "25px" }}>
                      SACEM TRAINING can provide you with expert support to
                        determine your training needs through an audit,
                        and propose the appropriate customized solution.
                      </p>
                      <p style={{ paddingLeft: "25px" }}>
                      Energy production (photovoltaic and wind)
                      </p>
                    </ul>
                  </div>
                </div>
              </section>
              {/* ============================================ */}
              <div>
                <div className="col-md-12 text-center">
                  <h3>OUR TRAINING AREAS</h3>
                </div>
                {/**section 1 */}
                <section style={{ paddingTop: "30px" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12"></div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "350px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                First aid and fire fighting
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>Working at height</li>
                                  <li>Fire audit</li>
                                  <li>Caces</li>
                                  <li>Chemical hazards</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "350px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">COMMUNICATION</h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>Time management</li>
                                  <li>Stress management</li>
                                  <li>Teamwork</li>
                                  <li>LEADERSHIP</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "350px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">LEAN MANAGEMENT </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>AMDEC</li>
                                  <li>KAIZEN</li>
                                  <li>5S</li>
                                  <li>LEADERSHIP</li>
                                  <li>
                                  Group Problem Solving Method
                                  (GPSM)
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/**section 2 */}

                <section style={{ paddingTop: "30px" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12"></div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "380px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">ENERGY</h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                  Industrial energy audit for company
                                    to appropriate practical tools
                                    tools
                                  </li>
                                  <li>Carbon assessment</li>
                                  <li>ISO 50001 internal auditors</li>
                                  <li>Energy regulatory watch</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "380px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                INDUSTRIAL RISKS
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                  Drawing up a hazard study and a
                                  P.O.I
                                  </li>
                                  <li>Chemical risk prevention</li>
                                  <li>
                                  Storage and transport management
                                  chemical products
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "380px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                Quality Safety Environment
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                  ISO 9001 Version 2015: Requirements and
                                  implementation techniques
                                  </li>
                                  <li>ISO 14001 Version 2015</li>
                                  <li>ISO 31000 risk management</li>
                                  <li>QSE internal audit</li>
                                  <li>
                                  Integrated management system requirements
                                  QSE
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/**section 3 */}
                <section style={{ paddingTop: "30px" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12"></div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "480px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                HUMAN RESOURCES MANAGEMENT
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                  Identification of training needs
                                  </li>
                                  <li>HRM dashboard</li>
                                  <li>Management and career development</li>
                                  <li>
                                  Personnel assessment techniques
                                  </li>
                                  <li>Management of occupational injury files</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "480px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                HEALTH & SAFETY AT WORK
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                  ISO 45001 version 2018: requirements
                                  and implementation techniques
                                  </li>
                                  <li>
                                  Occupational risk assessment and
                                  control measures
                                  </li>
                                  <li>
                                  NFC 18510 Electrical risk prevention
                                  prevention
                                  </li>
                                  <li>
                                  Workplace accident investigation and analysis
                                  </li>
                                  <li>
                                  Occupational health and safety regulations
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "480px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                PROJECTS MANAGEMENT
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>PMP certification preparation</li>
                                  <li>
                                  Project management fundamentals
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/**section 4 */}
                <section style={{ paddingTop: "30px" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12"></div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "300px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                Electrical authorization
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                  Maintenance of electrical installations
                                  </li>
                                  <li>
                                  Maintenance of transmission lines and
                                  HV/MV/LV substations
                                  </li>
                                  <li>
                                  Operation, maintenance and rehabilitation
                                  of MV/LV networks
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "300px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                Transformer maintenance
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>Preventive maintenance</li>
                                  <li>
                                  Maintenance of transmission lines and
                                  HV/MV/LV substations
                                  </li>
                                  <li>Corrective maintenance</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "300px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">Photovoltac</h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>Photovoltaic systems</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              className="button button-border"
                              href="/en/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              {/* ============================================ */}
              {/*Commercial Training*/}
              <section>
                <div className="row mt-100">
                  <div className="col-lg-12">
                    <h2 style={{ color: "#00356a" }}>
                    Sales representatives SACEM Training
                    </h2>
                    <StyledHeading className="h6-line"></StyledHeading>
                    <br />
                  </div>
                  <div className="col-lg-6 col-md-6 sm-mb-30">
                    <div className="team team-list">
                      <div className="team-photo">
                        <img
                          className="img-fluid mx-auto"
                          src="/assets/images/equipe_training/Battick.jpg"
                          alt="Mohamed battick"
                        />
                      </div>
                      <div className="team-description">
                        <div className="team-info">
                          <h5>
                            <a href="team-single.html">Mohamed Aziz BATTIK</a>
                          </h5>
                          <span>Commercial Training</span>
                        </div>
                        <div className="team-contact">
                          <span className="call"> (+216) 29 922 611</span>
                          <span className="email">
                            {" "}
                            <i className="fa fa-envelope-o" />
                            MohamedAziz.BATTIKH@sacemgroup.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="team team-list">
                      <div className="team-photo">
                        <img
                          className="img-fluid mx-auto"
                          src="/assets/images/equipe_training/radhia.jpg"
                          alt="Radhia mliki"
                        />
                      </div>
                      <div className="team-description">
                        <div className="team-info">
                          <h5>
                            <a href="team-single.html">Radhia MLIKI</a>
                          </h5>
                          <span>Commercial Training</span>
                        </div>
                        <div className="team-contact">
                          <span className="call"> (+216) 28 505 123</span>
                          <span className="email">
                            {" "}
                            <i className="fa fa-envelope-o" />
                            radia.mliki@sacemtraining.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* ============================================ */}
              {/* ============================================ */}
              {/*Partenaire*/}
              <section>
                <div className="row mt-100">
                  <div className="col-lg-12 col-md-12">
                    <h2 style={{ color: "#00356a" }}>Our customer references</h2>
                    <StyledHeading className="h6-line"></StyledHeading>
                    <br />
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="clients-list">
                      <div
                        className="owl-carousel"
                        data-nav-dots="false"
                        data-items={5}
                        data-md-items={4}
                        data-sm-items={3}
                        data-xs-items={2}
                        data-xx-items={2}
                      >
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Agil.png"
                            alt="Agil"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/client03.jpg"
                            alt="Groupe Chimique"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/CMR.jpg"
                            alt="CMR"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Fouledh.jpg"
                            alt="EL fouledh"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Hutch.jpg"
                            alt="Hutch"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/KBE.jpg"
                            alt="KBE"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/steg.jpg"
                            alt="STEG"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Warda.jpg"
                            alt="WARDA"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/CMR.jpg"
                            alt="CMR"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/fuba.jpg"
                            alt="Fuba"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/g.jpg"
                            alt="Électricité de Guinée"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/eico.jpg"
                            alt="EICO"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/groupe mmi.jpg"
                            alt="Groupe MMI"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/vitalait.jpg"
                            alt="Vitalait"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/sicam.jpg"
                            alt="SICAM"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/délice.jpg"
                            alt="DELICE"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/misfat.jpg"
                            alt="Misfat"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/eleonetech.jpg"
                            alt="Eleonetech"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/yazaki.jpg"
                            alt="YAZAKI"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/icar.jpg"
                            alt="Icar"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/safran.jpg"
                            alt="SAFRAN"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/giz.jpg"
                            alt="giz"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/kromberg.jpg"
                            alt="Kromberg"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/bpm.jpg"
                            alt="bpm"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/l'epi d'or.jpg"
                            alt="l'epi d'or"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/unimed.jpg"
                            alt="unimed"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/cpc.jpg"
                            alt="cpc"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/gruner.jpg"
                            alt="gruner"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/cofat.jpg"
                            alt="cofat"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/cnp.jpg"
                            alt="cnp"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/carthage cement.jpg"
                            alt="carthage cement"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/bcm.jpg"
                            alt="bcm"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/clinique elissa.jpg"
                            alt="Clinique elissa"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/omv.jpg"
                            alt="omv"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/poulina.jpg"
                            alt="poulina"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Amilcar Petroleum Operations.jpg"
                            alt="Amilcar Petroleum Operations"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Landor.jpg"
                            alt="Landor"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* ============================================ */}
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Training_en;
