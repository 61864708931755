import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Header_en from "./Header_en";
import Footer_en from "./Footer_en";

const Sav_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div style={{ paddingTop: "120px" }}>
        {/*=================================
 service*/}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">SAV & Repair</h2>
                </div>
              </div>
            </div>
            <h4 style={{ color: "black" }}>
            SACEM Industries offers a complete range of technical services:
            </h4>
            <br />

            {/* =========================================== */}
            <div className="row">
              <div className="col-lg-6 col-sm-6 mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">1</span>
                  <div className="media-body">
                    <h4>Transformer diagnostics </h4>
                    <p className="mb-0">
                    One of our experts' missions is to carry out diagnostics on transformers, covering:
                    </p>
                    <br />
                    <h6>Transformer off</h6>
                    <ul class="list list-unstyled list-hand ">
                      <li>Visual inspection.</li>
                      <li>Oil level check.</li>
                      <li>Insulation resistance measurement.</li>
                      <li>Measurement of ground resistance.</li>
                      <li>Checking paint coatings.</li>
                    </ul>
                    <br />
                    <h6>Transformer in service</h6>
                    <ul class="list list-unstyled list-hand ">
                      <li>Checking temperatures.</li>
                      <li>Detection of unusual vibrations.</li>
                      <li>Thermal camera diagnostics.</li>
                      <li>Measurement of harmonics on the low-voltage side.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">2</span>
                  <div className="media-body">
                    <h4>Servicing and Preventive Maintenance  </h4>
                    <p className="mb-0">
                    The after-sales service offers preventive maintenance programs,
                    on site or at the factory, including :
                    </p>
                    <br />
                    <ul class="list list-unstyled list-hand ">
                      <li>Cleaning and dust removal.</li>
                      <li>Inspection of transformer accessories.</li>
                      <li>Change of seals.</li>
                      <li>Replacement of silica gel.</li>
                      <li>Paint touch-up.</li>
                      <li>Checking MV/LV electrical connections.</li>
                      <li>Check electrical continuity.</li>
                      <li>Insulation resistance check.</li>

                      <li>Oil sample analysis.</li>
                      <li>Oil treatment.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-6 xs-mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">3</span>
                  <div className="media-body">
                    <h4> Diagnosis of capacitor banks </h4>
                    <p className="mb-0">Our after-sales service provides :</p>
                    <br />
                    <ul class="list list-unstyled list-hand ">
                      <li>Visual inspection</li>
                      <li>Electrical connection checks.</li>
                      <li>Verification of electrical components.</li>
                      <li>Network quality analysis.</li>
                      <li>Checking cos Φ.</li>

                      <li>Cleaning and dusting.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 xs-mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">4</span>
                  <div className="media-body">
                    <h4>Repair</h4>

                    <p className="mb-0">
                    SACEM Industries repairs all brands of
                    brands of :
                    </p>
                    <br />
                    <ul class="list list-unstyled list-hand ">
                      <li>
                      Class transformers up to 36 kV and power up to
                      up to 30 MVA .
                      </li>
                      <li>Capacitor banks</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="entry-button"
              style={{ marginLeft: 20, marginTop: "40px" }}
            >
              <a className="button button-border" href="/en/contact">
              Contact us
              </a>
            </div>
          </div>
        </section>
        {/*==============service===
         <section className="white-bg" style={{ paddingBottom: "80px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="section-title">
                  <h2>SAV Sacem</h2>
                  <p>
                    Notre service après-vente est le partenaire incontournable
                    pour garantir la performance, la fiabilité et la longévité
                    des installations de distribution électrique. Grâce à notre
                    expertise et notre réactivité et efficience, nous aidons nos
                    clients à minimiser les risques de pannes et maximiser la
                    fiabilité de leurs investissements. Nous vous offrons des
                    contrats de maintenance personnalisés, adaptés aux
                    caractéristiques uniques de votre parc de transformateurs et
                    à vos besoins spécifiques.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 sm-mt-30">
                <img
                  src="/assets/images/Products/SAV.png"
                  className="img-fluid full-width"
                  alt
                />
              </div>
            </div>
            <div className="entry-button" style={{ marginLeft: 20 }}>
              <a className="button button-border" href="/en/contact">
                Nous Contacter
              </a>
            </div>
          </div>
        </section>
        
        */}
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Sav_en;
