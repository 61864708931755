import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Transformateur_Special_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/transformateur/Triphase/slider-triphase-36-24.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Special Transformers</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Our ranges of Special Transformers
                  </h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            <div className="service-3">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div className="service-blog text-left">
                      {/** <h3 className="theme-color">BPN</h3>*/}

                      <h3 className="theme-color">Grounding transformers</h3>
                      <br />

                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>
                          Grounding transformers are used to provide a neutral
                          connection for earthing a three-phase network. This
                          type of transformer can also be fitted with a
                          secondary winding for coupling to an auxiliary load.
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{
                        paddingBottom: "1.25rem",
                        paddingLeft: "1.25rem",
                      }}
                    >
                      <a className="button button-border" href="/en/contact">
                        Contact us
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div className="col-lg-12 col-md-12">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div>
                          <img
                            className="img-fluid"
                            src="/assets/images/transformateur/Specials/BPN.png"
                            alt="Transformateurs de mise à la terre"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Specials/typesec.jpg"
                          alt="Transformateur d'isolement"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="slideInRight wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInRight",
                    }}
                  >
                    <div className="service-blog left text-left">
                      <h3 className="theme-color">Isolation transformer</h3>
                      <br />

                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderLeft: "0px solid white" }}
                      >
                        <li>
                          An isolation transformer is designed to electrically
                          isolate one electrical circuit from another, while
                          allowing power allowing power to be transferred. It is
                          commonly used to secure sensitive equipment. sensitive
                          equipment. (medical equipment, computer equipment,
                          etc.). This type of transformer can be dry or immersed
                          in oil.
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{
                        paddingBottom: "1.25rem",
                        paddingLeft: "1.25rem",
                      }}
                    >
                      <a className="button button-border" href="/en/contact">
                        Contact us
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div className="service-blog text-left">
                      <h3 className="theme-color">Autotransformer</h3>
                      <br />

                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>
                          Unlike conventional transformers where the primary
                          primary and secondary windings are completely separate
                          separate, the autotransformer uses a single winding
                          winding, with part of it in common. It is commonly
                          used for three-phase motor three-phase motor starting
                          applications as well as for adjusting voltage as
                          required. (in voltage stabilisers, etc.)
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{
                        paddingBottom: "1.25rem",
                        paddingLeft: "1.25rem",
                      }}
                    >
                      <a className="button button-border" href="/en/contact">
                        Contact us
                      </a>
                    </div>
                    <br />
                    <br />
                  </div>
                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div className="col-lg-12 col-md-12">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div>
                          <img
                            className="img-fluid"
                            src="/assets/images/transformateur/Specials/Autotransformateur.jpg"
                            alt="Autotransformateur"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Transformateur_Special_en;
