import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA component

const Form_carriers = () => {
  const [formData, setFormData] = useState({
    name: "",
    lastName: "", // Add last name field
    poste: "", // Add poste field
    email: "",
    phone: "",
    message: "",
  });

  // State to handle the uploaded file
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState(""); // State to handle file errors

  // State to handle the response or error messages
  const [responseMessage, setResponseMessage] = useState("");
  const [Nom, setNom] = useState("");
  const [isLoading, setIsLoading] = useState(false); // to manage loader status

  const recaptchaSiteKey = process.env.REACT_APP_reCaptcha;
  const recaptchaSiteKey_localhost = process.env.REACT_APP_reCaptcha_localhost;

  const [errors, setErrors] = useState({});
  const [Captcha, setCaptcha] = useState(true); // State to track captcha change and disable button
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission
  const maxFileSize = 1 * 1024 * 1024; // 1 MB in bytes
  // Handle captcha value
  const captchavalue = (e) => {
    setCaptcha(false);
  };

  // Handler to update state when input values change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const allowedTypes = ["application/pdf"];
    const file = e.target.files[0];
    setSelectedFile(e.target.files[0]);
    // Check if the file size exceeds 1 MB (1 MB = 1048576 bytes)
    if (file) {
      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        setErrors({
          ...errors,
          file: "Votre fichier doit être au format PDF.",
        });
        setSelectedFile(null); // Reset selected file if invalid type
      }
      // Check if the file size exceeds 1 MB
      else if (file.size > maxFileSize) {
        setErrors({
          ...errors,
          file: "La taille du fichier ne doit pas dépasser 1 Mo.",
        });
        setSelectedFile(null); // Reset selected file if too large
      } else {
        // If both type and size are valid, clear errors and set the file
        setErrors({ ...errors, file: "" });
        setSelectedFile(file);
      }
    }
  };

  // Validate the form fields
  const validateForm = () => {
    let formErrors = {};

    if (!formData.name.trim()) formErrors.name = "Name is required";
    if (!formData.lastName.trim())
      formErrors.lastName = "Last name is required"; // Validate last name
    if (!formData.poste.trim()) formErrors.poste = "Poste is required"; // Validate poste
    if (!formData.email.trim()) formErrors.email = "Email is required";
    if (!formData.phone.trim()) formErrors.phone = "Phone is required";
    if (!formData.message.trim()) formErrors.message = "Message is required";

    setErrors(formErrors);

    // If there are no errors, return true
    return Object.keys(formErrors).length === 0;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // display the loader

    // Validate form fields before submission
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    // Prevent submission if file exceeds the size limit
    if (fileError) {
      setIsLoading(false);
      alert(fileError); // Optionally show an alert to the user
      return;
    }

    console.log("Form validation successful, preparing request"); // Debug

    // Prepare the form data for 'multipart/form-data' request
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("lastName", formData.lastName); // Add last name to the formData
    formDataToSend.append("poste", formData.poste); // Add poste to the formData
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("message", formData.message);

    if (selectedFile) {
      formDataToSend.append("file", selectedFile);
    }

    console.log("Form data prepared:", formDataToSend); // Debug

    // API endpoint where data will be sent
    const apiUrl = process.env.REACT_APP_API_PowerAuto;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: formDataToSend, // Send 'multipart/form-data'
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response from server:", data); // Debug server response
        setNom(data.data.name);
        setResponseMessage("Form submitted successfully!");
        setIsSubmitted(true);
      } else {
        setResponseMessage("There was an error submitting the form.");
      }
    } catch (error) {
      setResponseMessage("An error occurred: " + error.message);
    }

    // Optionally, reset the form
    setFormData({
      name: "",
      lastName: "",
      poste: "",
      email: "",
      phone: "",
      message: "",
    });
    setTimeout(() => setIsLoading(false), 500); // hide the loader
  };

  return (
    <div className="col-lg-3 sm-mt-40" style={{ maxWidth: "35%" }}>
      {!isSubmitted ? (
        <div className="careers-from">
          {!isLoading ? (
            <div>
              <h4>Postuler</h4>
              <div id="formmessage">
                {responseMessage || "Success/Error Message Goes Here"}
              </div>
              <form>
                <div className="section-field mb-20 mt-20">
                  <label htmlFor="name">Nom</label>
                  <div className="field-widget">
                    <input
                      type="text"
                      className="form-control"
                      value={formData.name}
                      onChange={handleInputChange}
                      id="name"
                      name="name"
                      required
                    />
                    {errors.name && (
                      <span className="error-text">{errors.name}</span>
                    )}
                  </div>
                </div>

                {/* New Last Name field */}
                <div className="section-field mb-20">
                  <label htmlFor="lastName">Prénom:</label>
                  <div className="field-widget">
                    <input
                      type="text"
                      className="form-control"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      id="lastName"
                      name="lastName"
                      required
                    />
                    {errors.lastName && (
                      <span className="error-text">{errors.lastName}</span>
                    )}
                  </div>
                </div>

                {/* New Poste field */}
                <div className="section-field mb-20">
                  <label htmlFor="poste">Poste:</label>
                  <div className="field-widget">
                    <input
                      type="text"
                      className="form-control"
                      value={formData.poste}
                      onChange={handleInputChange}
                      id="poste"
                      name="poste"
                      required
                    />
                    {errors.poste && (
                      <span className="error-text">{errors.poste}</span>
                    )}
                  </div>
                </div>

                <div className="section-field mb-20">
                  <label>Email:</label>
                  <div className="field-widget">
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    {errors.email && (
                      <span className="error-text">{errors.email}</span>
                    )}
                  </div>
                </div>
                <div className="section-field mb-20">
                  <label htmlFor="Telephone">Téléphone:</label>
                  <div className="field-widget">
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                    />
                    {errors.phone && (
                      <span className="error-text">{errors.phone}</span>
                    )}
                  </div>
                </div>
                <div className="section-field mb-20">
                  <label htmlFor="Message">Message:</label>
                  <div className="field-widget">
                    <textarea
                      className="input-message form-control"
                      id="Message"
                      rows={7}
                      name="message"
                      defaultValue={""}
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                    />
                    {errors.message && (
                      <span className="error-text">{errors.message}</span>
                    )}
                  </div>
                </div>
                <div className="mb-20">
                  <div className="file-input">
                    <label htmlFor="exampleInputFile">Resume/CV:</label>
                    <input
                      type="file"
                      className="form-control-file form-control"
                      id="exampleInputFile"
                      onChange={handleFileChange}
                      accept=".pdf,.jpg,.png" // Accept certain file types
                    />
                    {errors.file && (
                      <span className="error-text" style={{ color: "red" }}>
                        {errors.file}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mb-20">
                  {/* reCAPTCHA Widget */}
                  <ReCAPTCHA
                    sitekey={recaptchaSiteKey}
                    onChange={captchavalue}
                  />
                </div>
                <button
                  className="button-border button"
                  onClick={handleSubmit}
                  disabled={Captcha || errors.file}
                  style={{
                    backgroundColor:
                      Captcha || errors.file ? "#e0e0e0" : "white",
                    color: Captcha || errors.file ? "#aaaaaa" : "black",
                  }}
                >
                  Postuler
                </button>
              </form>
            </div>
          ) : (
            <div style={{ paddingTop: "600px" }}>
              <img
                src="/assets/images/pre-loader/loader-21.gif"
                alt="Loading..."
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!isLoading && (
            <div style={{ paddingTop: "50px" }}>
              <h3>
                <span>{Nom},</span>
              </h3>
              <h5>
                Nous avons bien reçu votre demande et vous recevrez une réponse
                très bientôt.
              </h5>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Form_carriers;
