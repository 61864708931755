import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Historique_en = () => {
  const Textstyle = {
    fontSize: "22px",
    lineHeight: "28px",
    fontWeight: "500",
    color: "#222c5a",
  };
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);

  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
  page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          /*style={{ backgroundImage: "url(/assets/images/bg/02.jpg)" }}*/
          style={{
            backgroundImage: "url(/assets/images/Bannier/histoire.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>History</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>History</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================page-title */}
        {/*=================================blog timeline*/}
        <div className="container">
          <section className="white-bg blog timeline-sidebar page-section-ptb">
            <div className="container">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-9">
                  <ul className="timeline">
                    <li className="entry-date">
                      {" "}
                      <span>
                        <strong style={Textstyle}>2024</strong>{" "}
                      </span>
                    </li>
                    {/* =================2022===================== */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2022 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Design and manufacture of the 26 MVA power
                                transformer.
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ====================2021======================= */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2021 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Design and manufacture of the 20 MVA power
                                transformer.
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* =======================2020==================== */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2020 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Development of the energy management solution.
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* ==================2019========================= */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2019 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Homologation of HTA cells.
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* =======================2017==================== */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2017 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                SACEM POWER SYSTEMS created.
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* =========================================== */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2016 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Development of the smart transformer.
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ==================2007========================= */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2007 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>Privatisation of SACEM.</p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ==================2005========================= */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2005 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Design of a 15 MVA transformer.
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ==================1987========================= */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          1987 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                5 MVA transformer testing station.
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ===============1986============================ */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          1986 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                International Certification.
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ================1975=========================== */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          1975 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Our first export operations.
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* ================1966=========================== */}
                    <li
                      className="timeline-inverted"
                      style={{ marginBottom: "30px" }}
                    >
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#/"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          1966 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                1966, Creation of SACEM, a STEG subsidiary
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* =========================================== */}

                    <li
                      className="entry-date-bottom"
                      style={{ marginTop: "15px", display: "flex" }}
                    >
                      {" "}
                      <a href="#/">
                        <strong style={Textstyle}>SACEM creation</strong>{" "}
                      </a>
                    </li>
                    {/* =========================================== */}
                    <li
                      className="clearfix timeline-inverted"
                      style={{ float: "none" }}
                    />
                  </ul>
                </div>
                {/* ================================= */}
              </div>
            </div>
          </section>
        </div>
        {/*=================================blog timeline*/}
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Historique_en;
