import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "./Loader";
import Footer from "./Footer";

const Rse = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          /*style={{ backgroundImage: "url(/assets/images/bg/02.jpg)" }}*/
          style={{
            backgroundImage:
              "url(/assets/images/Entreprise/entreprise-prensentation.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Responsabilité Sociétale de l'entreprise</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>R S E</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/** */}

        <section className="page-section-ptb">
          <div className="container">
            <div
              className="row"
              style={{ textAlign: "justify", padding: "0 15px" }}
            >
              <div className="col-lg-6">
                <img
                  className="img-fluid full-width mb-20"
                  src="/assets/images/about/02.jpg"
                  alt="Responsabilité Sociétale SACEM"
                />
              </div>
              <div className="col-lg-6">
                <div className="section-title">
                  {/* <h6>Know About me </h6> */}
                  <h2 className="title-effect">
                    Notre Responsabilité Sociétale
                  </h2>
                  <p>
                    Chez SACEM Industries, nous croyons en un développement
                    durable et responsable.
                  </p>
                </div>
                <p>
                  Forts de notre héritage depuis 1966, nous intégrons les
                  préoccupations environnementales, sociales et économiques dans
                  notre stratégie et nos opérations.
                </p>
                <p>
                  Notre engagement RSE repose sur des valeurs de respect,
                  d'innovation, et de transparence pour répondre aux attentes de
                  nos parties prenantes et contribuer positivement à notre
                  environnement.
                </p>
                {/* <img className="mt-20" src="images/signature.png" alt /> */}
                <cite>
                  <span className="text-gray"> Le Directeur Général</span>
                  <br />
                  <strong>KHALED KAMMOUN</strong>
                </cite>
              </div>
            </div>
            {/** */}
            <div
              className="row"
              style={{
                padding: "0 15px",
                textAlign: "justify",
                marginTop: "20px",
              }}
            >
              {/* <div className="col-lg-8 col-md-6 mt-50"> */}

              {/* </div> */}
              <div style={{ padding: "0 15px" }}>
                <h3>Nos Engagements Clés :</h3>
                <h5 className="mt-30">1. Gouvernance Responsable</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Adopter des pratiques éthiques dans toutes nos activités.
                  </li>
                  <li>
                    Garantir la transparence dans nos relations avec nos
                    clients, fournisseurs et partenaires.
                  </li>
                  <li>
                    Former continuellement nos équipes à des pratiques conformes
                    aux standards internationaux.
                  </li>
                </ul>
                <h5 className="mt-30">2. Protection de l’Environnement</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Réduire l’empreinte écologique de nos opérations
                    industrielles en optimisant la consommation énergétique et
                    en minimisant les déchets.
                  </li>
                  <li>
                    Promouvoir l’utilisation de matériaux respectueux de
                    l’environnement, notamment pour nos transformateurs (comme
                    l’huile diélectrique FR3 respectueuse de l'environnement).
                  </li>
                  <li>
                    Mettre en œuvre des solutions de recyclage pour les
                    composants en fin de vie.
                  </li>
                </ul>
              </div>
            </div>
            {/** */}
            <div
              className="row"
              style={{ padding: "0 15px", textAlign: "justify" }}
            >
              <div className="col-lg-8 col-md-6 " style={{ marginTop: "0px" }}>
                <h5 className="mt-30">3. Engagement Social et Humain</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Assurer des conditions de travail sûres et inclusives pour
                    nos 350 employés.
                  </li>
                  <li>
                    Favoriser le développement personnel et professionnel par
                    des formations régulières.
                  </li>
                  <li>
                    Soutenir activement les initiatives locales en faveur du
                    développement socio-économique des communautés avoisinant
                    nos sites industriels.
                  </li>
                </ul>
                <h5 className="mt-30">4. Qualité et Innovation</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Continuer à développer des produits technologiquement
                    avancés qui répondent aux besoins spécifiques des clients
                    tout en réduisant leur impact environnemental.
                  </li>
                  <li>
                    Maintenir les plus hauts standards de qualité conformément
                    aux certifications internationales.
                  </li>
                  <li>
                    Encourager l'innovation pour intégrer des solutions
                    d'énergie verte dans nos offres.
                  </li>
                </ul>
                <h5 className="mt-30">
                  5. Engagement avec les Parties Prenantes
                </h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Collaborer avec nos partenaires pour promouvoir des
                    pratiques durables tout au long de la chaîne de valeur.
                  </li>
                  <li>
                    Communiquer de manière proactive sur nos performances RSE et
                    publier régulièrement des rapports de durabilité.
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 mt-50 text-white pos-r">
                <div
                  className="action-box small bg-overlay-black-60 parallax"
                  style={{ background: "url(images/bg/06.jpg)" }}
                >
                  <h3 className="text-white">Pour atteindre ces objectifs</h3>
                  <ul className="list list-arrow">
                    <li>
                      <p>
                        Nous nous engageons à établir des indicateurs de
                        performance clairs pour mesurer nos progrès.
                      </p>
                    </li>
                    <li>
                      <p>
                        Un comité RSE a été créé pour planifier, superviser et
                        évaluer les initiatives mises en œuvre.
                      </p>
                    </li>
                    <li>
                      <p>
                        Nous renforcerons le dialogue avec nos parties prenantes
                        pour ajuster nos actions en fonction des évolutions et
                        des attentes.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Rse;
