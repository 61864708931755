import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Eems_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
    page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{
            backgroundImage: "url(/assets/images/Power/eems.jpg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SEMS/IEMS</h1>
                  <p>Solution SEMS / IEMS</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>ENERGY MANAGEMENT SYSTEM</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
solution SEMS */}
        <section className="page-section-ptb gray-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h2 className="title-effect" style={{ marginBottom: "40px" }}>
                    Substation Energy Management System – SEMS
                  </h2>
                  <div className="section-title">
                    <p>
                      SEMS is an energy management system that enables real-time
                      supervision of MV/LV transformer substations. transformer
                      substations, SEMS makes it possible to measure, centralise
                      the data and events collected in the substation, and send
                      this information remotely to a monitoring platform.
                      monitoring platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="img-fluid mx-auto mt-30"
                  src="/assets/images/Power/cofr4.png"
                  alt="SEMS"
                />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-center">
                  <a className="button button-border" href="/contact">
                    Contact us
                  </a>
                  <a
                    className="button button-border"
                    style={{ width: 182 }}
                    href="/assets/brochures/Brochure SS SEMS IEMS septembre 2020 final.pdf"
                  >
                    Brochure
                  </a>
                </div>
              </div>
              <div className="col-lg-6 sm-mt-50 align-self-center">
                <div className="section-title">
                  <h2 className="theme-color">SEMS</h2>
                </div>
                <p>
                  SEMS is an energy management system that enables real-time
                  real time remote supervision of measurement data and events
                  and events at MV/LV transformer substations.
                </p>
                <p>The SEMS system has the following functions :</p>
                <div className="row slideInRight wow">
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-signal" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">
                          Monitor the main components of a substation MV/LV
                          SUBSTATION.
                        </h5>
                        <p>Environment:Workstation temperature</p>
                        <p>
                          MV/LV transformer:Transformer temperature
                          <br />
                          Status of DMCR or DGPT2 or Buccholz alarms on the
                          transformer.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-layout" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Visual interface</h5>
                        <p>
                          Measuring and collecting the main transformer output
                          U/V/A/P/Q/S/EA/ER/F/PF.
                        </p>
                        <p>
                          Measure and collect the main energy efficiency
                          efficiency parameters: THDI/THDU/Harmonic ratios up to
                          the 63rd rank.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-panel" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Management efficiency</h5>
                        <p>
                          Monitor these electrical quantities, generate alarms
                          alarms or events.
                        </p>
                        <p>
                          Record, time-stamp and archive these events and
                          measurements.
                        </p>
                        <p>
                          Record locally or remotely via a means of
                          communication (GSM/GPRS, Ethernet) measurements,
                          alarms and and events to an IT monitoring platform
                          SUBSTATION SMART SOLUTION’ IT monitoring platform for
                          analysis and supervision.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-package" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Integrated management</h5>
                        <p>
                          Sending alarms and events to a monitoring monitoring
                          platform for analysis and supervision.
                        </p>
                        <p>
                          Produce periodic reports, edit measurement tables
                          Excel format and view load curves. load curves.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-money" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Economical application</h5>
                        <p>
                          Remotely control the switching devices in the MV
                          cubicles and main LV board.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
    solution PLATFORM */}
        {/*=================================
POURQUOI CE SYSTÈME */}
        <section
          id="about-us"
          className="app-home page-section-ptb gray-bg pt-0"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h2 className="theme-color">WHY THIS SYSTEM</h2>
                <br />
                <ul className="list list-unstyled list-hand">
                  <li>
                    Concept of the Internet of Things (IoT), the interconnection
                    between the Internet and environments: remote monitoring of
                    MV/LV transformer substations.
                  </li>
                  <li>
                    Better perception of electricity consumption and transformer
                    transformer loads.
                  </li>
                  <li>Demonstration of phase imbalances.</li>
                  <li>
                    Prioritisation of energy efficiency actions. efficiency
                    actions.
                  </li>
                  <li>Optimisation of electricity consumption.</li>
                  <li>
                    Compliance with standards:{" "}
                    <strong>
                      {" "}
                      CEI 61557-12, CEI 62053-22, CEI 62053-23, CEI 61000-4/-3
                      and CEI 61010-1/-2.
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
POURQUOI CE SYSTÈME */}
        {/*=================================
Solution IEMS */}
        <section className="page-section-ptb gray-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h2 className="theme-color">
                    Industrial Energy Management System – IEMS
                  </h2>
                  <div className="section-title">
                    <p>
                      IEMS is an energy management system for remote, real-time
                      remote supervision of low-voltage distribution panels,
                      IEMS measures and centralises data and events events
                      collected from electrical feeders, and send this
                      information send this information remotely to a monitoring
                      platform. monitoring platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="img-fluid mx-auto mt-30"
                  src="/assets/images/Power/cofr4.png"
                  alt="IEMS"
                />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-center">
                  <a className="button button-border" href="/contact">
                    Contact us
                  </a>
                  <a
                    className="button button-border"
                    style={{ width: 182 }}
                    href="/assets/brochures/Brochure SS SEMS IEMS septembre 2020 final.pdf"
                  >
                    Brochure
                  </a>
                </div>
              </div>
              <div className="col-lg-6 sm-mt-50 align-self-center">
                <div className="section-title">
                  <h2 className="title-effect" style={{ color: "#00356a" }}>
                    IEMS
                  </h2>
                </div>
                <p>
                  IEMS is an energy management system that enables real-time
                  real-time remote monitoring of measurement data and events and
                  events from electrical distribution panels such as Low Voltage
                  Switchboards - Electrical cabinets.
                </p>
                <p>The functionalities of the IEMS system are as follows :</p>
                <div className="row slideInRight wow">
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-signal" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Remote management</h5>
                        <p>
                          Record locally or remotely via a means of
                          communication (GSM/GPRS, Ethernet) measurements,
                          alarms and and events to an IT monitoring platform
                          FACTORY SMART SOLUTION’ IT monitoring platform for
                          analysis and supervision.
                        </p>
                        <p>
                          Remote control of the switching devices at the main
                          main circuit breaker.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-layout" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Visual interface</h5>
                        <p>
                          Produce periodic reports, edit measurement tables in
                          Excel format measurement tables in Excel format and
                          view load curves for each feeder on the main LV board.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-panel" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Management efficiency</h5>
                        <p>
                          Measure and collect the main electrical quantities LV
                          electrical outgoing feeders U/V/A/P/Q/S/EA/ER/F/PF.
                        </p>
                        <p>
                          Measure and collect the main energy efficiency
                          efficiency parameters: THDI/THDU/Harmonic ratios up to
                          the 63rd rank.
                        </p>
                        <p />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan">
                        <span className="ti-package" />
                      </div>
                      <div className="feature-info">
                        <h5 className="text-back">Integrated management</h5>
                        <p>
                          Monitor these electrical quantities, generate alarms
                          alarms or events Record, time-stamp and archive and
                          archive these events and measurements for each
                          outgoing feeder.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-10">
                    <div className="feature-text left-icon">
                      <div className="feature-icon theme-color-cyan"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
    solution IEMS */}
        {/*=================================
POURQUOI CE SYSTÈME IEMS*/}
        <section
          id="about-us"
          className="app-home page-section-ptb gray-bg pt-0"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h2 className="theme-color">WHY THIS SYSTEM</h2>
                <br />
                <ul className="list list-unstyled list-hand">
                  <li>
                    The concept of the Internet of Things (IoT), the
                    interconnection between the Internet and physical
                    environments.
                  </li>
                  <li>Remote monitoring of departures.</li>
                  <li>
                    Better understanding of electricity consumption and loads on
                    the various outgoing feeders.
                  </li>
                  <li>Demonstration of phase imbalances.</li>
                  <li>
                    Prioritisation of energy efficiency actions. efficiency
                    actions.
                  </li>
                  <li>Optimisation of electricity consumption.</li>
                  <li>
                    Compliance with standards:{" "}
                    <strong>
                      {" "}
                      CEI 61557-12, CEI 62053-22, CEI 62053-23, CEI 61000-4/-3
                      and CEI 61010-1/-2.
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
POURQUOI CE SYSTÈME IEMS*/}
        {/*=================================
solution detail */}
        <section className="our-services page-section-ptb gray-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-title text-center">
                  <h2 className="title-effect">SPECIFICATIONS</h2>
                  <h6>
                    The main features of SMART TRANSFORMER platform enable you
                    to:
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="feature-text box-shadow text-center mb-30 white-bg slideInUp wow">
                  <div className="feature-icon">
                    <span
                      aria-hidden="true"
                      className="ti-settings theme-color-cyan"
                    />
                  </div>
                  <div className="fature-info">
                    <h4>Technical descriptions of SEMS/IEMS systems</h4>
                    <br />
                    <button
                      className="button button-border"
                      data-toggle="modal"
                      data-target=".bd-01-modal-lg"
                    >
                      Details
                    </button>

                    <div
                      className="modal fade bd-01-modal-lg"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="modal-title" id="01ModalLongTitle">
                              <div className="section-title mb-10">
                                <h3>SEMS/IEMS</h3>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Fermer"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body text-justify">
                            <h4>Technical descriptions of SEMS/IEMS systems</h4>
                            {/* <img
                              className="img-fluid mx-auto mt-20"
                              src="/assets/images/Power/01.jpg"
                              alt
                            /> */}
                            <p className="mt-20">
                              SACEM SMART dataloggers are housed inside an
                              inside IP 54 enclosures to form a complete
                              perfectly adapted to its environment. environment.
                            </p>
                            <p>
                              To make installation of the enclosures easy while
                              ensuring maximum safety, the control units data
                              loggers incorporate plug and play connectors
                              dedicated to each function:
                            </p>
                            <ul className="list list-unstyled list-hand">
                              <li>
                                CT secondary inputs (current measurement).
                              </li>
                              <li>LV voltage and power supply inputs.</li>
                              <li>Modbus TCP/IP communication link output.</li>
                              <li>Pt100 temperature sensor inputs.</li>
                              <li>Digital sensor inputs.</li>
                              <li>Digital output sensor.</li>
                              <li>Sensor inputs Analogue 4-20 mA.</li>
                            </ul>
                            <h4 className="mt-30">Communication</h4>
                            <img
                              className="img-fluid mx-auto mt-20"
                              src="/assets/images/Power/communication.PNG"
                              alt="Systèmes SEMS/IEMS"
                            />
                            <p className="mt-20">
                              Our SEMS/IEMS management systems enable
                              communication via :
                            </p>
                            <ul className="list list-arrow">
                              <li>
                                An Ethernet port (RJ45 socket) for connection
                                connection to a modem router or switch present
                                in the workstation, for an internet and/or
                                intranet connection.
                              </li>
                              <li>A GSM/GPRS modem.</li>
                            </ul>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="feature-text box-shadow text-center mb-30 white-bg slideInUp wow">
                  <div className="feature-icon">
                    <span
                      aria-hidden="true"
                      className="ti-bolt theme-color-cyan"
                    />
                  </div>
                  <div className="fature-info">
                    <h4>System architecture</h4>
                    <br />
                    <br />
                    <button
                      className="button button-border"
                      data-toggle="modal"
                      data-target=".bd-02-modal-lg"
                    >
                      Details
                    </button>

                    <div
                      className="modal fade bd-02-modal-lg"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="modal-title" id="02ModalLongTitle">
                              <div className="section-title mb-10">
                                <h3 />
                              </div>
                            </div>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Fermer"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body text-justify">
                            <h4>System architecture</h4>
                            <img
                              className="img-fluid mx-auto mt-20"
                              src="/assets/images/Power/Architectures-EEMS.PNG"
                              alt="Architecture du système"
                            />
                            <br />
                            <br />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="feature-text box-shadow text-center mb-30 white-bg slideInUp wow">
                  <div className="feature-icon">
                    <span
                      aria-hidden="true"
                      className="ti-package theme-color-cyan"
                    />
                  </div>
                  <div className="fature-info">
                    <h4>Technical specifications</h4>
                    <button
                      className="button button-border"
                      data-toggle="modal"
                      data-target=".bd-03-modal-lg"
                    >
                      Details
                    </button>

                    <div
                      className="modal fade bd-03-modal-lg"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="modal-title" id="01ModalLongTitle">
                              <div className="section-title mb-10">
                                <h3></h3>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Fermer"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body text-justify">
                            <h4>
                              Electricity management system dataloggers systems
                            </h4>
                            <p>
                              Our SACEM SMART dataloggers can collect the
                              following electrical data:
                            </p>
                            <ul className="list list-hand">
                              <li>LV voltages.</li>
                              <li>LV currents:</li>
                            </ul>
                            <ul
                              className="list list-arrow"
                              style={{ marginLeft: "20px" }}
                            >
                              <li>Load currents (phases).</li>
                              <li>
                                Unbalance current (neutral current current).
                              </li>
                            </ul>
                            <ul className="list list-hand">
                              <li>Active, reactive and apparent power.</li>
                              <li>Active and reactive energy.</li>
                              <li>The power factor.</li>
                              <li>
                                Frequency, rates of harmonic disturbance (THD,
                                distortion factor).
                              </li>
                            </ul>
                            <img
                              className="img-fluid mx-auto mt-20"
                              src="/assets/images/Power/Cartestiques.PNG"
                              alt="Caractéristiques techniques"
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="feature-text box-shadow text-center mb-30 white-bg slideInUp wow">
                  <div className="feature-icon">
                    <span
                      aria-hidden="true"
                      className="ti-shield theme-color-cyan"
                    />
                  </div>
                  <div className="fature-info">
                    <h4>IT monitoring platform</h4>
                    <button
                      className="button button-border"
                      data-toggle="modal"
                      data-target=".bd-04-modal-lg"
                    >
                      Details
                    </button>

                    <div
                      className="modal fade bd-04-modal-lg"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="modal-title" id="04ModalLongTitle">
                              <div className="section-title mb-10">
                                <h3 style={{ color: "#00356a" }}>
                                  SUBSTATION SMART SOLUTION
                                </h3>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Fermer"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body text-justify">
                            <p className="mt-20">
                              <strong>SUBSTATION SMART SOLUTION</strong>, is an
                              IT platform that allows remote display of
                              measurement data and measurement data and events
                              at the MV/LV transformer MV/LV transformer
                              substation. This platform enables.
                            </p>
                            <div className="content-wrapper-substation">
                              <ul
                                className="column-list-substation list list-arrow"
                                style={{ wordBreak: "break-all" }}
                              >
                                <li>Generation of periodic reports.</li>
                                <li>
                                  Generation of measurement tables <br /> in
                                  Excel format.
                                </li>
                                <li>Display of equipment operating status.</li>
                                <li>
                                  The display of curves relating to the measured
                                  parameters.
                                </li>
                                <li>
                                  Recording and archiving events and
                                  measurements.
                                </li>
                                <li>Alarm threshold settings.</li>
                                <li>
                                  Remote control of switching devices
                                  (optional).
                                </li>
                              </ul>
                              <img
                                className="img-fluid-substation mx-auto mt-20"
                                src="/assets/images/Power/substation-solution.PNG"
                                alt="SUBSTATION SMART SOLUTION"
                              />
                            </div>
                            <br />
                            <br />
                            <div className="divider double" />
                            <br />
                            <br />
                            <div className="section-title mb-10">
                              <h3
                                className="title-effect"
                                style={{ color: "#00356a" }}
                              >
                                FACTORY SMART SOLUTION
                              </h3>
                            </div>
                            <div className="content-wrapper-substation">
                              <ul
                                className="column-list-substation list list-arrow"
                                style={{ wordBreak: "break-all" }}
                              >
                                <li>Generation of periodic reports.</li>
                                <li>
                                  Generation of measurement tables in Excel
                                  format.
                                </li>
                                <li>
                                  Displaying the operating status of the of
                                  electrical outlets.
                                </li>
                                <li>
                                  The curves relating to the parameters measured
                                  parameters for each departure.
                                </li>
                                <li>
                                  The recording and archiving of events and
                                  measurements.
                                </li>
                                <li>
                                  Setting the alarm thresholds for each outgoing
                                  for each departure.
                                </li>
                                <li>
                                  Integration of other non electrical parameters
                                  (optional).
                                </li>
                                <li>
                                  Remote control of switching devices
                                  (optional).
                                </li>
                              </ul>
                              <img
                                className="img-fluid-substation mx-auto mt-20"
                                src="/assets/images/Power/Factory_substation.png"
                                alt="FACTORY SMART SOLUTION"
                                style={{ maxWidth: "350px" }}
                              />
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
solution detail */}
      </div>
      <div>
        <Footer_en />{" "}
      </div>
    </div>
  );
};

export default Eems_en;
