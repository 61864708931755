import React from "react";

const Footer_en = () => {
  return (
    <div>
      <div id="footer-fixed" className="footer black-bg">
        <div className="page-section-pt">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 sm-mb-30">
                <div className="footer-logo">
                  <img
                    id="logo_img"
                    src="/assets/images/logos/logo1.png"
                    alt="logo"
                    style={{ width: "122px", height: "90px" }}
                  />
                  <p className="pb-10">
                    {" "}
                    Since 1966 SACEM Industries has been the leading
                    manufacturer in the manufacture and sale of single-phase,
                    three-phase and single-phase, three-phase and special and
                    special transformers...
                  </p>
                </div>
                <div className="social-icons color-hover">
                  <ul>
                    <li className="social-facebook">
                      <a
                        href="https://www.facebook.com/sacemindustries/?locale=fr_FR"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="social-linkedin">
                      <a
                        href="https://fr.linkedin.com/company/sacem-group"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-linkedin" />{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 sm-mb-30">
                <div className="footer-useful-link footer-hedding">
                  <h6 className="text-white mb-30 mt-10 text-uppercase">
                    Navigate
                  </h6>
                  <ul>
                    <li>
                      <a href="/en">Home</a>
                    </li>
                    <li>
                      <a href="/en/services">Services</a>
                    </li>
                    <li>
                      <a href="/en/products">Products</a>
                    </li>

                    <li>
                      <a href="/en/contactus">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 xs-mb-30">
                <div className="footer-useful-link footer-hedding">
                  <h6 className="text-white mb-30 mt-10 text-uppercase">
                    Useful links
                  </h6>
                  <ul>
                    <li>
                      <a href="/en/news">News</a>
                    </li>
                    <li>
                      <a href="/en/carriers">Careers</a>
                    </li>
                    <li>
                      {" "}
                      <a href="/en/faqs">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <h6 className="text-white mb-30 mt-10 text-uppercase">
                  CONTACT US
                </h6>
                <ul className="addresss-info">
                  <li>
                    <i className="fa fa-map-marker" />{" "}
                    <p>
                      Head office :Imm. Mak Crown, 3rd floor, Rue du Lac
                      Tanganyika Les Berges du Lac 1053 Tunis, Tunisia
                    </p>{" "}
                  </li>
                  <li>
                    <i className="fa fa-map-marker" />{" "}
                    <p>
                      Factory :Zone industrielle Sidi Rezig Tinja - 7050 Menzel
                      Bourguiba, Tunisia
                    </p>{" "}
                  </li>
                  <li>
                    <i className="fa fa-phone" />{" "}
                    <a href="tel:0021671966986">
                      {" "}
                      <span>+(216) 71 966 986 </span>{" "}
                    </a>{" "}
                  </li>
                  <li>
                    <i className="fa fa-envelope-o" />
                    <a href="mailto:contact@sacemgroup.com">
                      contact@sacemgroup.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-widget mt-20">
              <div className="row">
                <div className="col-md-12 text-center">
                  <p className="mt-15">
                    {" "}
                    © 2025 <span id="copyright"> </span>{" "}
                    <a href="/"> SACEM GROUP </a> All Rights Reserved{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer_en;
